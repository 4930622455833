import React from 'react';

import { CustomDialog } from 'components';

const ContractDialog = ({ open, setOpen, row, onSubmit }) => {
  const actions = [
    {
      label: 'ΑΚΥΡΩΣΗ',
      type: 'secondary',
      onClick: () => setOpen(false),
    },
    {
      label: 'ΕΝΕΡΓΟΠΟΙΗΣΗ',
      type: 'primary',
      onClick: () => {
        setOpen(false);
        onSubmit();
      },
    },
  ];
  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      title={'Ενεργοποίηση σύμβασης'}
      actions={actions}
    >
      {row && (
        <div className="d-flex flex-column align-items-center">
          <div>
            Είσαι βέβαιος ότι θες να ενεργοποιήσεις την σύμβαση του &nbsp;
          </div>
          <div className="text-semibold">
            {row.firstName} {row.lastName};
          </div>
        </div>
      )}
    </CustomDialog>
  );
};

export default ContractDialog;
