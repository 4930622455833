import { types } from './types';

const INITIAL_STATE = {
  data: {},
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.SET_CURRENT_USER.SUCCESS:
      return {
        ...state,
        data: payload,
      };
    default:
      return state;
  }
};

export default reducer;
