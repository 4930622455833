import omit from 'lodash/omit';

import { successNotifications, errorNotification } from 'store/utils';
import { types } from './types';
import {
  getAllDocuments,
  getDocument,
  addDocumentWithId,
  updateDocument,
  deleteDocument,
} from 'firebase';

export const updateJobField = payload => dispatch => {
  dispatch({ type: types.UPDATE_JOB_FIELD.SUCCESS, payload });
};

export const clearJobFields = () => dispatch => {
  dispatch({ type: types.CLEAR_FIELDS.SUCCESS, payload: {} });
};

export const addJob = payload => dispatch => {
  dispatch({ type: types.ADD_JOB.START, payload: { loading: true } });
  addDocumentWithId('jobs', payload.id, payload)
    .then(() => {
      return [
        dispatch({
          type: types.ADD_JOB.SUCCESS,
          payload: { loading: false, success: true },
        }),
        dispatch(successNotifications().create),
      ];
    })
    .catch(error => {
      return [
        dispatch({ type: types.ADD_JOB.ERROR }),
        dispatch(errorNotification(error.message)),
      ];
    });
};

export const getJobs = () => dispatch => {
  dispatch({ type: types.GET_JOBS.START });
  getAllDocuments('jobs')
    .then(response => {
      return dispatch({
        type: types.GET_JOBS.SUCCESS,
        payload: response,
      });
    })
    .catch(error => {
      return [
        dispatch({ type: types.GET_JOBS.ERROR }),
        dispatch(errorNotification(error.message)),
      ];
    });
};

export const getJob = collectionId => dispatch => {
  dispatch({ type: types.GET_JOB.START });
  getDocument('jobs', collectionId)
    .then(response => {
      return dispatch({
        type: types.GET_JOB.SUCCESS,
        payload: response,
      });
    })
    .catch(error => {
      return [
        dispatch({ type: types.GET_JOB.START }),
        dispatch(errorNotification(error.message)),
      ];
    });
};

export const setSelectedJob = payload => dispatch => {
  dispatch({ type: types.SET_SELECTED_JOB.SUCCESS, payload });
};

export const setJobToEdit = payload => dispatch => {
  dispatch({ type: types.SET_JOB_TO_EDIT.SUCCESS, payload });
};

export const setJobEmployee = payload => dispatch => {
  dispatch({ type: types.SET_JOB_EMPLOYEE.SUCCESS, payload });
};

export const updateRestJob = payload => dispatch => {
  const { documentId } = payload;
  updateDocument('jobs', documentId, omit(payload, ['documentId']))
    .then(response => {
      return dispatch({ type: types.UPDATE_REST_JOB.SUCCESS });
    })
    .catch(error => {
      return dispatch({ type: types.UPDATE_REST_JOB.ERROR });
    });
};

export const updateJob = payload => dispatch => {
  const { documentId } = payload;
  dispatch({ type: types.UPDATE_JOB.START });
  updateDocument('jobs', documentId, omit(payload, ['documentId']))
    .then(response => {
      return [
        dispatch({
          type: types.UPDATE_JOB.SUCCESS,
          payload: omit(payload, 'documentId'),
        }),
        dispatch(successNotifications().update),
      ];
    })
    .catch(error => {
      return [
        dispatch({
          type: types.UPDATE_JOB.ERROR,
        }),
        dispatch(errorNotification(error.message)),
      ];
    });
};

export const updateEmployee = payload => dispatch => {
  const { documentId, callback } = payload;
  dispatch({ type: types.UPDATE_EMPLOYEE.START });
  updateDocument(
    'employees',
    documentId,
    omit(payload, ['documentId', 'callback'])
  )
    .then(response => {
      callback();
      return [
        dispatch({
          type: types.UPDATE_EMPLOYEE.SUCCESS,
          payload: omit(payload, ['documentId', 'callback']),
        }),
      ];
    })
    .catch(error => {
      return dispatch({ type: types.UPDATE_EMPLOYEE.ERROR });
    });
};

export const editJob = payload => dispatch => {
  const { jobId } = payload;
  dispatch({ type: types.EDIT_JOB.START });
  updateDocument('jobs', jobId, omit(payload, ['jobId']))
    .then(response => {
      return [
        dispatch({ type: types.EDIT_JOB.SUCCESS }),
        dispatch(successNotifications().update),
      ];
    })
    .catch(error => {
      return [
        dispatch({ type: types.EDIT_JOB.ERROR }),
        dispatch(errorNotification(error.message)),
      ];
    });
};

export const clearState = () => dispatch => {
  dispatch({ type: types.CLEAR_STATE.SUCCESS });
};

export const deleteConsultingFromJob =
  (consultingId, jobId, updatedConsultings) => dispatch => {
    dispatch({ type: types.DELETE_SELECTED_CONSULTING.START });

    deleteDocument('consultings', consultingId)
      .then(() => {
        updateDocument('jobs', jobId, { consultings: updatedConsultings }).then(
          () => {
            return [
              dispatch({
                type: types.DELETE_SELECTED_CONSULTING.SUCCESS,
                payload: {
                  consultingId,
                },
              }),
              dispatch(successNotifications().update),
            ];
          }
        );
      })
      .catch(error => {
        return [
          dispatch({ type: types.DELETE_SELECTED_CONSULTING.ERROR }),
          dispatch(errorNotification(error.message)),
        ];
      });
  };

export const deleteJob = payload => dispatch => {
  const { id, callback } = payload;
  dispatch({ type: types.DELETE_JOB.START });
  deleteDocument('jobs', id)
    .then(() => {
      dispatch({ type: types.DELETE_JOB.SUCCESS, payload: id });
      dispatch(successNotifications().update);
      callback(false);
    })
    .catch(error => {
      dispatch({ type: types.DELETE_JOB.ERROR });
      dispatch(errorNotification(error.message));
    });
};
