import React from 'react';
import Button from '@mui/material/Button';

const CustomButton = ({
  onClick,
  variant = 'contained',
  disabled,
  label,
  ...rest
}) => {
  return (
    <Button onClick={onClick} variant={variant} disabled={disabled} {...rest}>
      {label}
    </Button>
  );
};

export default CustomButton;
