import { PDFViewer } from '@react-pdf/renderer';

const ReportPDFViewer = ({ children }) => {
  return (
    <PDFViewer showToolbar={true} height="100%">
      {children}
    </PDFViewer>
  );
};

export default ReportPDFViewer;
