import React from 'react';
import Input from '@mui/material/Input';
import isEmpty from 'lodash/isEmpty';

const ExternalEmployee = ({ selectedJob, selectedEmployee, setEmployee }) => {
  return (
    <div className="external-employee-container">
      <Input
        fullWidth
        placeholder="Χρόνος"
        type="number"
        inputProps={{
          min: 0,
          max: 1,
          step: 0.1,
        }}
        value={selectedEmployee.occupation || ''}
        onChange={event => {
          const basicPayload = {
            id: selectedJob.id,
            name: selectedJob.name,
            occupation: event.target.value,
          };
          setEmployee({
            occupation: event.target.value,
            activeJobs: isEmpty(selectedEmployee.activeJobs)
              ? [{ ...basicPayload }]
              : selectedEmployee.activeJobs.some(
                  job => job.id === selectedJob.id
                )
              ? selectedEmployee.activeJobs.map(job =>
                  job.id === selectedJob.id
                    ? {
                        ...job,
                        occupation: event.target.value,
                      }
                    : { ...job }
                )
              : [...selectedEmployee.activeJobs, { ...basicPayload }],
          });
        }}
      />
    </div>
  );
};

export default ExternalEmployee;
