import toNumber from 'lodash/toNumber';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import get from 'lodash/get';
import moment from 'moment';

export const calculateTotalCost = ({ job }) => {
  const fields = job.fields;
  const net = isNumber(fields.netCost)
    ? fields.netCost
    : toNumber(get(fields, 'netCost', '').replace(',', '.')) || 0;
  const vat = get(fields, 'vat', '') || 0;
  const rest = isNumber(fields.restExpenses)
    ? fields.restExpenses
    : toNumber(get(fields, 'restExpenses', '').replace(',', '.')) || 0;
  const expenses = isNumber(fields.expenses)
    ? fields.expenses
    : toNumber(get(fields, 'expenses', '').replace(',', '.')) || 0;

  const subTotal = net + (net * expenses) / 100 + rest;
  const total = subTotal * vat + subTotal;

  return total.toFixed(2);
};

export const handleDisabled = ({ fields, job }) => {
  const requiredFields = fields.filter(f => f.required);
  let disabled = requiredFields.some(f => !job.fields[f.name]);
  return disabled || job.loading;
};

export const handleChange = ({
  event,
  field,
  component,
  dispatch,
  updateJobField,
}) => {
  let value = '';
  switch (component) {
    case 'datepicker':
      value = moment(event).format();
      break;
    case 'checkbox':
      value = event.target.checked;
      break;
    case 'text':
      value =
        field.type === 'number'
          ? event.target.value.replace(',', '.')
          : event.target.value;
      break;
    default:
      value = event.target.value;
      break;
  }
  dispatch(
    updateJobField({
      key: field.name,
      value,
    })
  );
};

export const checkJobConflicts = ({ job, jobs }) => {
  const initialJob = jobs.find(j => j.id === job.fields.id);
  const { startDate, endDate } = job.fields;
  const hasChangedDate = !!(
    moment(startDate).diff(moment(initialJob.startDate)) ||
    moment(endDate).diff(moment(initialJob.endDate))
  );
  if (!job.fields.employees || isEmpty(job.fields.employees) || !hasChangedDate)
    return false;

  return true;
};
