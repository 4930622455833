import isEmpty from 'lodash/isEmpty';
import toNumber from 'lodash/toNumber';
import values from 'lodash/values';
import omit from 'lodash/omit';
import moment from 'moment';
import { getDocument } from 'firebase';
import { formattedNumber } from 'utils';

export const calculateMonths = ({ selectedJob }) => {
  let months = [];
  let startDate = moment(selectedJob.startDate);
  let endDate = moment(selectedJob.endDate);

  while (startDate.isBefore(endDate)) {
    months.push({
      displayValue: startDate.format('MMM YYYY'),
      value: startDate.format('MM/YYYY'),
    });
    startDate = startDate.add(1, 'month');
  }

  return months;
};

export const calculateOccupationLeft = ({ month, selectedEmployee }) => {
  let occupation = {};
  let totalOccupation = 0;
  if (isEmpty(selectedEmployee.activeJobs)) {
    occupation = {};
  } else {
    selectedEmployee.activeJobs.map(j => {
      totalOccupation = totalOccupation + toNumber(j.occupation[month] || 0);
      return totalOccupation;
    });
  }

  occupation = {
    [month]: totalOccupation,
  };

  const value = occupation[month];
  let occupationLeft = isEmpty(occupation)
    ? 1
    : value
    ? toNumber((1 - value).toFixed(2))
    : 1;
  return occupationLeft;
};

export const handleSubmitDisabled = ({ selectedEmployee, selectedJob }) => {
  let disabled = false;
  calculateMonths({ selectedJob }).map(month => {
    if (
      calculateOccupationLeft({
        month: month.value,
        selectedEmployee,
      }) < 0
    ) {
      disabled = true;
    }
    return disabled;
  });
  return disabled || selectedEmployee.loading;
};

export const calculateTotals = ({ selectedEmployee }) => {
  const { occupation } = selectedEmployee;
  if (isEmpty(occupation)) return '-';

  let totalOccupation = 0;

  values(occupation).map(
    value => (totalOccupation = totalOccupation + toNumber(value))
  );

  return {
    totalOccupation: toNumber(totalOccupation.toFixed(3)),
    totalCost: Math.round(totalOccupation * selectedEmployee.rate),
  };
};

export const onSubmit = ({
  selectedEmployee,
  selectedJob,
  updateEmployee,
  updateJob,
  updateRestJob,
  getEmployees,
  dispatch,
}) => {
  const isExternal = selectedEmployee.isExternal;
  const employee = {
    ...omit(selectedEmployee, ['loading']),
    totalCost: calculateTotals({ selectedEmployee }).totalCost,
    totalOccupation: calculateTotals({ selectedEmployee }).totalOccupation,
  };
  const activeOccupation = {
    id: selectedJob.id,
    name: selectedJob.name,
    occupation: selectedEmployee.occupation,
  };

  const restJobsEmployeeParticipating = selectedEmployee.activeJobs.filter(
    job => job.id !== selectedJob.id
  );

  // Update every job on which this employee is participating.
  if (restJobsEmployeeParticipating.length) {
    restJobsEmployeeParticipating.map(j => {
      return getDocument('jobs', j.id).then(response => {
        const jobEmployees = response.employees;
        const activeJobs = selectedEmployee.activeJobs;
        const jobExternalEmployees = response.externalEmployees;
        dispatch(
          updateRestJob({
            documentId: response.id,
            employees: jobEmployees.map(em =>
              em.id === selectedEmployee.id ? { ...em, activeJobs } : em
            ),
            externalEmployees: jobExternalEmployees
              ? jobExternalEmployees.map(em =>
                  em.id === selectedEmployee.id ? { ...em, activeJobs } : em
                )
              : [],
          })
        );
      });
    });
  }

  if (isExternal) {
    const externalEmployee = {
      ...omit(selectedEmployee, ['loading']),
      totalCost: toNumber(selectedEmployee.occupation) * selectedEmployee.rate,
      totalOccupation: selectedEmployee.occupation,
    };

    dispatch(
      updateEmployee({
        documentId: selectedEmployee.id,
        activeJobs: isEmpty(selectedEmployee.activeJobs)
          ? [activeOccupation]
          : selectedEmployee.activeJobs.some(j => j.id === selectedJob.id)
          ? selectedEmployee.activeJobs.map(j =>
              j.id === selectedJob.id ? activeOccupation : j
            )
          : [...selectedEmployee.activeJobs, activeOccupation],
        callback: () => dispatch(getEmployees()),
      })
    );

    dispatch(
      updateJob({
        documentId: selectedJob.id,
        employees: selectedJob.employees || [],
        externalEmployees: selectedJob.externalEmployees
          ? selectedJob.externalEmployees.some(
              em => em.id === selectedEmployee.id
            )
            ? [
                ...selectedJob.externalEmployees.map(em =>
                  em.id === selectedEmployee.id ? externalEmployee : { ...em }
                ),
              ]
            : [...selectedJob.externalEmployees, externalEmployee]
          : [externalEmployee],
      })
    );
  } else {
    dispatch(
      updateEmployee({
        documentId: selectedEmployee.id,
        activeJobs: isEmpty(selectedEmployee.activeJobs)
          ? [activeOccupation]
          : selectedEmployee.activeJobs.some(j => j.id === selectedJob.id)
          ? selectedEmployee.activeJobs.map(j =>
              j.id === selectedJob.id ? activeOccupation : j
            )
          : [...selectedEmployee.activeJobs, activeOccupation],
        callback: () => dispatch(getEmployees()),
      })
    );
    dispatch(
      updateJob({
        documentId: selectedJob.id,
        employees: selectedJob.employees
          ? selectedJob.employees.some(em => em.id === selectedEmployee.id)
            ? [
                ...selectedJob.employees.map(em =>
                  em.id === selectedEmployee.id ? employee : { ...em }
                ),
              ]
            : [...selectedJob.employees, employee]
          : [employee],
      })
    );
  }
};

export const startEmployeeContract = ({
  updateJob,
  updateEmployee,
  selectedJob,
  selectedEmployee,
  dispatch,
}) => {
  const isExternal = selectedEmployee.isExternal;
  dispatch(
    updateJob({
      documentId: selectedJob.id,
      employees: isExternal
        ? selectedJob.employees
        : selectedJob.employees.map(em =>
            em.id === selectedEmployee.id
              ? {
                  ...em,
                  hasContract: true,
                  contractStartDate: moment().format(),
                }
              : { ...em }
          ),
      externalEmployees: isExternal
        ? selectedJob.externalEmployees.map(em =>
            em.id === selectedEmployee.id
              ? {
                  ...em,
                  hasContract: true,
                  contractStartDate: moment().format(),
                }
              : { ...em }
          )
        : selectedJob.externalEmployees,
    })
  );

  dispatch(
    updateEmployee({
      documentId: selectedEmployee.id,
      activeJobs: selectedEmployee.activeJobs.map(job =>
        job.id === selectedJob.id
          ? {
              ...job,
              hasContract: true,
              contractStartDate: moment().format(),
            }
          : { ...job }
      ),
    })
  );
};

export const linkJobWithConsulting = (
  updateJob,
  editConsulting,
  dispatch,
  selectedJob,
  selectedConsulting
) => {
  dispatch(
    updateJob({
      documentId: selectedJob.id,
      ...omit(selectedJob, ['id']),
      consultings: selectedJob?.consultings
        ? [selectedConsulting, ...selectedJob.consultings]
        : [selectedConsulting],
    })
  );
  dispatch(
    editConsulting({
      consultingId: selectedConsulting.id,
      ...omit(selectedConsulting, ['id']),
      job: { ...selectedJob },
    })
  );
};

export const unlinkJobFromConsulting = (
  updateJob,
  editConsulting,
  dispatch,
  selectedJob,
  selectedConsulting
) => {
  dispatch(
    updateJob({
      documentId: selectedJob.id,
      ...omit(selectedJob, ['id']),
      consultings: selectedJob?.consultings
        ? [
            ...selectedJob.consultings.filter(
              c => c.id !== selectedConsulting.id
            ),
          ]
        : [],
    })
  );
  dispatch(
    editConsulting({
      consultingId: selectedConsulting.id,
      ...omit(selectedConsulting, ['id', 'job']),
      job: null,
    })
  );
};

export const options = ({ employees }) => {
  const newEmployees = [...employees];
  return newEmployees
    .sort((a, b) => a.lastName.localeCompare(b.lastName))
    .map(employee => ({
      label: `${employee.lastName} ${employee.firstName} ${
        employee.isExternal ? ' - Εξωτερικός ' : ''
      } (${formattedNumber(employee.rate)})`,
      value: employee.id,
      firstName: employee.firstName,
      lastName: employee.lastName,
    }));
};

export const calculateJobTotalCosts = selectedJob => {
  const { employees, consultings, externalEmployees, restExpenses } =
    selectedJob;
  let internalEmployeesCost = 0;
  let externalEmployeesCost = 0;
  let totalConsulting = 0;

  if (employees && !isEmpty(employees)) {
    employees.map(
      em => (internalEmployeesCost = internalEmployeesCost + em.totalCost)
    );
  }

  if (externalEmployees && !isEmpty(externalEmployees)) {
    externalEmployees.map(
      ext => (externalEmployeesCost = externalEmployeesCost + ext.totalCost)
    );
  }

  if (consultings) {
    consultings.map(
      c => (totalConsulting = totalConsulting + toNumber(c.amount))
    );
  }

  let employeesAndConsultingCost =
    internalEmployeesCost + totalConsulting + externalEmployeesCost;

  let totalExpenses = employeesAndConsultingCost * selectedJob.expenses || 0;
  let netCost =
    employeesAndConsultingCost + totalExpenses + toNumber(restExpenses || 0);
  let totalVat = netCost * selectedJob.vat;
  let totalJobBudget = netCost + totalVat;

  return {
    internalEmployeesCost: formattedNumber(internalEmployeesCost),
    totalVat: formattedNumber(totalVat),
    totalExpenses: formattedNumber(totalExpenses),
    totalJobBudget: formattedNumber(totalJobBudget),
    totalConsulting: formattedNumber(totalConsulting),
    externalEmployeesCost: formattedNumber(externalEmployeesCost),
    netCost: formattedNumber(netCost),
    totalCostUnformatted: netCost,
    internalEmployeesCostUnformatted: internalEmployeesCost,
  };
};
