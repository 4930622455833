import { formatAmount } from 'utils';

const resolveNameVariable = n => {
  switch (n) {
    case 'internalEmployeesCostUnformatted':
      return 'Εσωτερικοί εργαζόμενοι:';
    case 'internalEmployeesCost':
      return 'Εσωτερικοί εργαζόμενοι:';
    case 'totalVat':
      return 'ΦΠΑ:';
    case 'restExpenses':
      return 'Λοιπές δαπάνες:';
    case 'totalExpenses':
      return 'Έμμεσες δαπάνες:';
    case 'totalJobBudget':
      return 'Σύνολο έργου:';
    case 'totalConsulting':
      return 'Εξωτερική ανάθεση:';
    case 'externalEmployeesCost':
      return 'Εξωτερικοί εργαζόμενοι:';
    case 'netCost':
      return 'Καθαρό κόστος έργου:';
    case 'totalCostUnformatted':
      return 'Καθαρό κόστος έργου';
    default:
      return '';
  }
};

const resolveJobTotalCost = jobTotals => {
  const totals = [];
  jobTotals?.totalConsulting &&
    totals.push({
      name: resolveNameVariable('totalConsulting'),
      value: jobTotals.totalConsulting,
    });
  jobTotals?.internalEmployeesCost &&
    totals.push({
      name: resolveNameVariable('internalEmployeesCost'),
      value: jobTotals.internalEmployeesCost,
    });
  jobTotals?.externalEmployeesCost &&
    totals.push({
      name: resolveNameVariable('externalEmployeesCost'),
      value: jobTotals.externalEmployeesCost,
    });
  jobTotals?.totalExpenses &&
    totals.push({
      name: resolveNameVariable('totalExpenses'),
      value: jobTotals.totalExpenses,
    });
  jobTotals?.restExpenses &&
    totals.push({
      name: resolveNameVariable('restExpenses'),
      value: jobTotals.restExpenses,
    });
  jobTotals?.netCost &&
    totals.push({
      name: resolveNameVariable('netCost'),
      value: jobTotals.netCost,
    });
  jobTotals?.totalVat &&
    totals.push({
      name: resolveNameVariable('totalVat'),
      value: jobTotals.totalVat,
    });
  jobTotals?.totalJobBudget &&
    totals.push({
      name: resolveNameVariable('totalJobBudget'),
      value: jobTotals.totalJobBudget,
    });

  return totals;
};

const resolveJobEmployees = selectedJob => {
  const jobEmployees = [];
  Array.isArray(selectedJob?.employees) &&
    selectedJob.employees.length > 0 &&
    jobEmployees.push(...selectedJob.employees);

  Array.isArray(selectedJob?.externalEmployees) &&
    selectedJob.externalEmployees.length > 0 &&
    jobEmployees.push(...selectedJob.externalEmployees);
  return jobEmployees.sort((e1, e2) => (e1.lastName > e2.lastName ? 1 : -1));
};

const resolveJobConsultings = selectedJob =>
  selectedJob?.consultings && selectedJob.consultings.length
    ? [].concat(
        [
          {
            description: 'Περιγραφή',
            amount: 'Ποσό',
          },
        ],
        selectedJob.consultings.map(c => ({
          description: c.description,
          amount: c.amount,
        }))
      )
    : null;

const resolveStatus = status => {
  switch (status) {
    case 1:
      return 'Σχεδιασμός';
    case 2:
      return 'Υλοποίηση';
    case 3:
      return 'Ολοκλήρωση';
    default:
      return '-';
  }
};

export {
  resolveJobEmployees,
  resolveJobTotalCost,
  formatAmount,
  resolveStatus,
  resolveJobConsultings,
};
