import React from 'react';
import ReactExport from 'react-export-excel';
import { localeSort } from 'utils';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExcelGenerator = ({ employees }) => {
  const cloneEmployees = [...employees];
  const sortedEmployees = localeSort(cloneEmployees, 'lastName');
  return (
    <ExcelFile
      element={
        <div className="header-container__excel">Λήψη Excel εργαζομένων</div>
      }
      filename="Εργαζόμενοι"
    >
      <ExcelSheet data={sortedEmployees} name="Εγαζόμενοι">
        <ExcelColumn label="Ονοματεπώνυμο" value={col => col.fullName} />
        <ExcelColumn label="ΑΦΜ" value="taxNumber" />
        <ExcelColumn label="Κόστος" value="rate" />
        <ExcelColumn
          label="Εξωτερικός"
          value={col => (col.isExternal ? 'Ναι' : 'Όχι')}
        />
        <ExcelColumn
          label="Ειδικότητα"
          value={col =>
            col.position === 1
              ? 'Μηχανικός'
              : col.position === 2
              ? 'Διοικητικός'
              : col.position === 3
              ? 'Νομικός'
              : "'Αλλο"
          }
        />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default ExcelGenerator;
