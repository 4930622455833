import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

import { CustomDialog } from 'components';
import { formattedNumber } from 'utils';

const SelectedConsultings = ({
  open,
  setOpen,
  selectedJob,
  onDeleteConsulting,
  deleteDisabled,
}) => {
  const { consultings } = selectedJob;
  return (
    <CustomDialog open={open} setOpen={setOpen} title={'Εξωτερικές αναθέσεις'}>
      <div className="selected-consultings-dialog">
        <div className="d-flex justify-content-between column-titles">
          <div className="consulting-column">Περιγραφή</div>
          <div className="consulting-column">Ποσό</div>
          <div className="consulting-column" />
        </div>
        <div className="consultings-list">
          {consultings.map((consulting, index) => (
            <div key={index} className="d-flex justify-content-between">
              <div className="consulting-column">{consulting.description}</div>
              <div className="consulting-column">
                {formattedNumber(consulting.amount)} €
              </div>
              <div
                className={`delete-consulting consulting-column ${
                  deleteDisabled ? 'disable-delete' : ''
                }`}
              >
                <DeleteIcon
                  color="error"
                  sx={{ width: 18, height: 18 }}
                  onClick={() => {
                    const newConsultings = consultings.filter(
                      con => con.id !== consulting.id
                    );
                    onDeleteConsulting(consulting.id, newConsultings);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </CustomDialog>
  );
};

export default SelectedConsultings;
