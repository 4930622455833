import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';

import { EditRowButton, DeleteRowButton } from './components';
import { DeleteDialog } from 'components';

const CustomTable = ({
  columns,
  rows,
  renderColumnValue,
  handleEdit,
  withEdit,
  handleDelete,
  handleNavigate,
  maxHeight,
  withFooter,
  renderFooterValue,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <div className="table-list">
      <TableContainer
        sx={{ maxHeight: maxHeight || 'none' }}
        className="table-list__container"
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow
                    hover
                    key={index}
                    className="table-list__row"
                    sx={{ position: 'relative' }}
                  >
                    {columns.map(column => {
                      const value = row[column.name];
                      const shouldNavigate = column.withNavigate;
                      return (
                        <TableCell
                          key={column.name}
                          onClick={() => {
                            if (shouldNavigate) {
                              handleNavigate(row);
                            }
                          }}
                          className={`${
                            shouldNavigate && 'table-list__navigate'
                          }`}
                        >
                          {renderColumnValue(value, column, row) || '-'}
                        </TableCell>
                      );
                    })}
                    {withEdit && withEdit(row) && (
                      <EditRowButton
                        onClick={() => {
                          handleEdit(row);
                        }}
                      />
                    )}
                    {handleDelete && (
                      <DeleteRowButton
                        onClick={() => {
                          setSelectedRow(row);
                          setOpen(true);
                        }}
                      />
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
          {withFooter && (
            <TableFooter>
              <TableRow>
                {columns.map((column, cIndex) => {
                  return (
                    <TableCell key={cIndex}>
                      {renderFooterValue(column)}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
      <DeleteDialog
        open={open}
        setOpen={setOpen}
        handleDelete={({ setOpen }) => handleDelete(selectedRow, setOpen)}
        selectedRow={selectedRow}
      />
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default CustomTable;
