import React from 'react';

const FullHeightComponent = ({ children, className }) => {
  return (
    <div
      id="fullheight-component-wrapper"
      className={`component-wrapper ${className || ''}`}
    >
      {children}
    </div>
  );
};

export default FullHeightComponent;
