import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { Fab } from 'components';

import { getEmployee } from 'store/employees/actions';
import { getJobs } from 'store/jobs/actions';
import { formattedNumber, employeePositions } from 'utils';
import { calculateTotals } from 'views/job/utils';

import { FSDialog } from 'components';
import { ReportPDFViewer } from 'components';

import { resovleJobStatus } from './components/employeePdfReport/helpers';

import EmployeePdfReport from './components/employeePdfReport';

const prepareJobs = (employee, jobs) => {
  return employee && jobs
    ? employee.activeJobs.map(j => ({
        name: j.name,
        status: jobs.find(job => job.id === j.id).status,
        totalCost: formattedNumber(
          Math.ceil(
            Object.values(j.occupation).reduce(
              (accum, curr) =>
                accum + (isNaN(parseFloat(curr)) ? 0.0 : parseFloat(curr)),
              0.0
            ) * parseFloat(employee.rate)
          )
        ),
      }))
    : [];
};

const Employee = () => {
  const [openFullScreenDialog, setOpenFullScreenDialog] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jobs = useSelector(state => state.jobs.jobs);
  const selectedEmployee = useSelector(
    state => state.employees.selectedEmployee
  );
  const { id } = useParams();

  const renderPosition = () => {
    const position = employeePositions.find(
      pos => pos.value === selectedEmployee.position
    );
    return (position && position.label) || '';
  };

  useEffect(() => {
    dispatch(getEmployee(id));
    dispatch(getJobs());
  }, [dispatch, id]);

  return (
    <>
      <div className="d-flex justify-content-between mt-3">
        <Fab
          onClick={() =>
            navigate('/employees', {
              replace: true,
            })
          }
          title="Πίσω"
          icon={<ArrowBackRoundedIcon />}
        />
        <Fab
          onClick={() => setOpenFullScreenDialog(true)}
          title="Εξαγωγή ως PDF"
          icon={<PictureAsPdfIcon fontSize="medium" />}
        />
      </div>
      <div className="selected-employee">
        <div className="selected-employee__card-details">
          <div className="d-flex">
            <div className="detail-label">Όνομα: &nbsp;</div>
            <div>{selectedEmployee.firstName}</div>
          </div>
          <div className="d-flex">
            <div className="detail-label">Επώνυμο: &nbsp;</div>
            <div>{selectedEmployee.lastName}</div>
          </div>
          <div className="d-flex">
            <div className="detail-label">Ειδικότητα: &nbsp;</div>
            <div>{renderPosition()}</div>
          </div>
          <div className="d-flex">
            <div className="detail-label">Κόστος: &nbsp;</div>
            <div>{formattedNumber(selectedEmployee.rate)} €</div>
          </div>
        </div>
        {selectedEmployee.activeJobs &&
          !isEmpty(selectedEmployee.activeJobs) &&
          jobs && (
            <div className="selected-employee__jobs selected-employee__card-details">
              <div className="text-center text-semibold">Έργα</div>
              {selectedEmployee.activeJobs.map((job, index) => {
                const jobStatus = Array.isArray(jobs)
                  ? jobs.filter(j => j.id === job.id)[0].status
                  : 0;
                return (
                  <div key={index}>
                    <div className="jobs-participating">
                      <div className="d-flex">
                        <div className="detail-label">Όνομα:</div>
                        <div
                          className="job-navigate"
                          onClick={() =>
                            navigate(`/job/${job.id}`, {
                              replace: true,
                            })
                          }
                        >
                          {job.name}
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="detail-label">Κόστος:</div>
                        <div>
                          {formattedNumber(
                            calculateTotals({
                              selectedEmployee: {
                                occupation: job.occupation,
                                rate: selectedEmployee.rate,
                              },
                            }).totalCost
                          )}{' '}
                          €
                        </div>
                      </div>
                      {/*  */}
                      <div className="d-flex">
                        <div className="detail-label">Κατάσταση:</div>
                        <div>{resovleJobStatus(jobStatus)}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
      </div>
      {selectedEmployee && jobs && (
        <FSDialog
          title="Εργαζόμενος - Εξαγωγή σε PDF"
          open={openFullScreenDialog}
          closeHandler={() => setOpenFullScreenDialog(false)}
        >
          <ReportPDFViewer>
            <EmployeePdfReport
              employee={{
                ...selectedEmployee,
                preparedJobs: prepareJobs(selectedEmployee, jobs),
              }}
            />
          </ReportPDFViewer>
        </FSDialog>
      )}
    </>
  );
};

export default Employee;
