import { types } from './types';

const INITIAL_STATE = {
  consultings: [],
  consultingToCreate: {
    fields: {},
  },
  loading: false,
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.GET_CONSULTINGS.START:
      return {
        ...state,
        loading: true,
      };
    case types.GET_CONSULTINGS.SUCCESS:
      return {
        ...state,
        consultings: payload,
        loading: false,
      };
    case types.GET_CONSULTINGS.ERROR:
      return {
        ...state,
        loading: false,
      };
    case types.UPDATE_CONSULTING_FIELD.SUCCESS:
      return {
        ...state,
        consultingToCreate: {
          ...state.consultingToCreate,
          fields: {
            ...state.consultingToCreate.fields,
            [payload.key]: payload.value,
          },
        },
      };
    case types.ADD_CONSULTING.START:
      return {
        ...state,
        consultingToCreate: {
          ...state.consultingToCreate,
          ...payload,
        },
      };
    case types.ADD_CONSULTING.SUCCESS:
      return {
        ...state,
        consultingToCreate: {
          ...state.consultingToCreate,
          ...payload,
        },
      };
    case types.ADD_CONSULTING.ERROR:
      return {
        ...state,
        consultingToCreate: {
          ...state.consultingToCreate,
          loading: false,
          success: false,
        },
      };
    case types.CLEAR_CONSULTING_FIELDS.SUCCESS:
      return {
        ...state,
        consultingToCreate: {
          fields: {},
        },
      };
    case types.SET_CONSULTING_TO_EDIT.SUCCESS:
      return {
        ...state,
        consultingToCreate: {
          fields: payload,
        },
      };
    case types.EDIT_CONSULTING.START:
      return {
        ...state,
        consultingToCreate: {
          ...state.consultingToCreate,
          loading: true,
        },
      };
    case types.EDIT_CONSULTING.SUCCESS:
      return {
        ...state,
        consultingToCreate: {
          ...state.consultingToCreate,
          loading: false,
          success: true,
        },
      };
    case types.EDIT_CONSULTING.ERROR:
      return {
        ...state,
        consultingToCreate: {
          ...state.consultingToCreate,
          loading: false,
          success: false,
        },
      };
    case types.DELETE_CONSULTING.SUCCESS:
      return {
        ...state,
        consultings: state.consultings.filter(job => job.id !== payload),
      };
    default:
      return state;
  }
};

export default reducer;
