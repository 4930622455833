import React from 'react';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';

import { routes } from 'routes/config';

const Home = () => {
  const navigate = useNavigate();

  const renderSubLabel = name => {
    switch (name) {
      case 'jobs':
        return 'Δες αναλυτικά τα έργα σου.';
      case 'employees':
        return 'Δες αναλυτικά τους εργαζόμενους.';
      case 'consulting':
        return 'Δες αναλυτικά τις εξωτερικές αναθέσεις.';
      default:
        return '';
    }
  };

  return (
    <div container className="home-container">
      {/* <div className="home-container__left-side">
        <h2>Μια εφαρμογή, αμέτρητες δυνατότητες!</h2>
        <h4>Η οργάνωση των project σου γίνεται πλέον εύκολη.</h4>
      </div> */}
      <div className="home-container__right-side">
        <div className="title">ΑΜΕΣΗ ΠΡΟΣΒΑΣΗ</div>
        <div className="font-14">
          Δες πώς μπορείς να διευκολύνεις την καθημερινότητα σου!
        </div>
        <div className="route-tiles">
          {routes
            .filter(route => route.usedOnSidebar)
            .map((route, index) => (
              <div
                key={index}
                className="route-item"
                onClick={() => navigate(route.path, { replace: true })}
              >
                <div className="d-flex align-items-center">
                  <IconButton>{route.Icon}</IconButton>
                  <div className="label">{route.label}</div>
                </div>
                <div className="sublabel">{renderSubLabel(route.name)}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
