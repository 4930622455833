import { addDoc, setDoc, doc, collection } from 'firebase/firestore';
import { db } from './';

const addUser = async (collection, documentId, name, lastname, sex) => {
  await setDoc(doc(db, collection, documentId), {
    name,
    lastname,
    sex,
    admin: true, // @todo Need to be changed while create user ui is ready.
    accessRights: {
      read: true,
      write: true,
    },
  });
};

const addDocument = async (payload, dbCollection) => {
  // addDoc auto-generates id for document, //setDoc you must specify id for document
  const document = await addDoc(collection(db, dbCollection), payload);
  return document;
};

const addDocumentWithId = async (collection, documentId, payload) => {
  await setDoc(doc(db, collection, documentId), payload);
};

export { addUser, addDocument, addDocumentWithId };
