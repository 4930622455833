import { types } from './types';

const INITIAL_STATE = {
  users: [],
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.GET_USERS.START:
      return {
        ...state,
        loading: true,
      };
    case types.GET_USERS.SUCCESS:
      return {
        ...state,
        users: payload,
        loading: false,
      };
    case types.GET_USERS.ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
