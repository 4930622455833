import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import { FullHeightComponent } from 'components';

const Loading = ({ customText = 'Παρακαλούμε περίμενε' }) => {
  return (
    <FullHeightComponent className="d-flex flex-column align-items-center justify-content-center">
      <CircularProgress />
      <div>{customText}</div>
    </FullHeightComponent>
  );
};

export default Loading;
