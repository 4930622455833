import React from 'react';
import { Document, Text, Page, Font } from '@react-pdf/renderer';

import {
  Table,
  TableHeader,
  TableBody,
  TableCell,
  DataTableCell,
} from '@david.kucsai/react-pdf-table';

import Comfortaa from 'fonts/Comfortaa/ComfortaaRegular.ttf';

import { ReportType, styles } from './config';

Font.register({
  family: 'Comfortaa',
  src: Comfortaa,
});

const ReportPDFDocument = ({
  data,
  type,
  projectName = 'MasterBudget',
  companyName = 'ΝΕΑ ΜΗΤΡΟΠΟΛΙΤΙΚΗ ΑΤΤΙΚΗ',
}) => {
  const resolvedTitle = ReportType[type] ? ReportType[type].title : '';
  return (
    <Document
      style={styles.document}
      title={`${projectName} | ${resolvedTitle}`}
    >
      <Page size="A4" style={styles.page} orientation="landscape">
        <Text style={styles.title}>
          {`${projectName} | ${companyName} | ${resolvedTitle}`}
        </Text>
        <Table data={data}>
          <TableHeader>
            {ReportType[type]
              ? ReportType[type].fields.map((f, idx) => (
                  <TableCell key={idx} style={styles.tablecell}>
                    {f}
                  </TableCell>
                ))
              : '-'}
          </TableHeader>
          <TableBody>
            {ReportType[type]
              ? ReportType[type].callbacks.map((c, idx) => (
                  <DataTableCell
                    key={idx}
                    style={styles.datatablecell}
                    getContent={c}
                  />
                ))
              : ''}
          </TableBody>
        </Table>
      </Page>
    </Document>
  );
};

export { ReportType };
export default ReportPDFDocument;
