import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUser } from 'store/user/actions';
import { getJobs } from 'store/jobs/actions';
import { getEmployees } from 'store/employees/actions';
import { getConsultings } from 'store/consultings/actions';
import isEmpty from 'lodash/isEmpty';

import ApplicationRouter from 'routes';
import { getAuthUser } from 'firebase';
import './styles/main.scss';

const theme = createTheme({
  typography: {
    fontFamily: `'Comfortaa', cursive, sans-serif`,
    allVariants: {
      color: '#0A0A0A',
    },
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      main: '#3b59c4',
    },
    error: {
      main: '#ef3340',
    },
  },
  components: {
    MuiTableFooter: {
      styleOverrides: {
        root: {
          position: 'sticky',
          bottom: 0,
          backgroundColor: '#fff',
        },
      },
    },
  },
});

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.data);

  useEffect(() => {
    getAuthUser(user => dispatch(setCurrentUser(user)));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(user)) {
      dispatch(getJobs());
      dispatch(getEmployees());
      dispatch(getConsultings());
    }
  }, [user, dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Container maxWidth="false" disableGutters className="app-container">
          <ApplicationRouter />
        </Container>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
