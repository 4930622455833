export const fields = [
  {
    label: 'Όνομα',
    name: 'firstName',
    component: 'text',
    required: true,
  },
  {
    label: 'Επώνυμο',
    name: 'lastName',
    component: 'text',
    required: true,
  },
  {
    label: 'Διαχειριστής',
    name: 'admin',
    component: 'text',
    required: true,
  },
];
