import React from 'react';
import ReactExport from 'react-export-excel';
import moment from 'moment';
import toNumber from 'lodash/toNumber';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExcelEmployees = ({ jobs }) => {
  return (
    <ExcelFile
      element={<div className="header-container__excel">Λήψη Excel έργων</div>}
      filename="Έργα"
    >
      <ExcelSheet data={jobs} name="Έργα">
        <ExcelColumn label="Όνομα" value="name" />
        <ExcelColumn
          label="Έναρξη"
          value={col => moment(col.startDate).format('DD/MM/YYYY')}
        />
        <ExcelColumn
          label="Λήξη"
          value={col => moment(col.endDate).format('DD/MM/YYYY')}
        />
        <ExcelColumn label="Εκτιμώμενο κόστος ανθρωποχρόνου" value="netCost" />
        <ExcelColumn label="ΦΠΑ" value={col => `${col.vat * 100}%`} />
        <ExcelColumn
          label="Έμμεσες δαπάνες"
          value={col => (col.expenses ? `${col.expenses * 100}%` : '-')}
        />
        <ExcelColumn label="Λοιπά κόστη" value="restExpenses" />
        <ExcelColumn label="Φορέας χρηματοδότησης" value="beneficiary" />
        <ExcelColumn
          label="Κατάσταση"
          value={col =>
            col.status === 1
              ? 'Σχεδιασμός'
              : col.status === 2
              ? 'Υλοποίηση'
              : 'Ολοκλήρωση'
          }
        />
        <ExcelColumn
          label="Εργαζόμενοι"
          value={col => (col.employees ? col.employees.length : 0)}
        />
        <ExcelColumn
          label="Κόστος εργαζομένων"
          value={col => {
            let total = 0;
            if (col.employees) {
              for (let i = 0; i <= col.employees.length; i++) {
                const cost = col.employees[i] ? col.employees[i].totalCost : 0;
                total = total + cost;
              }
            }
            return total;
          }}
        />
        <ExcelColumn
          label="Εξωτερικοί"
          value={col =>
            col.externalEmployees ? col.externalEmployees.length : 0
          }
        />
        <ExcelColumn
          label="Κόστος εξωτερικών"
          value={col => {
            let total = 0;
            if (col.externalEmployees) {
              for (let i = 0; i <= col.externalEmployees.length; i++) {
                const cost = col.externalEmployees[i]
                  ? col.externalEmployees[i].totalCost
                  : 0;
                total = total + cost;
              }
            }
            return total;
          }}
        />
        <ExcelColumn
          label="Εξωτερικές αναθέσεις"
          value={col => {
            let total = 0;
            if (col.consultings) {
              for (let i = 0; i <= col.consultings.length; i++) {
                const cost = col.consultings[i]
                  ? toNumber(col.consultings[i].amount)
                  : 0;
                total = total + cost;
              }
            }
            return total;
          }}
        />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default ExcelEmployees;
