import { StyleSheet } from '@react-pdf/renderer';
import { formatAmount } from 'utils';

import { resolveEmployeePosition, resovleJobStatus } from './helpers';

export const styles = StyleSheet.create({
  document: {
    fontFamily: 'Comfortaa',
  },
  page: {
    padding: '28px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  title: {
    fontSize: 16,
    marginBottom: '8px',
  },
  subtitle: {
    marginTop: '8px',
    fontSize: 12,
    marginBottom: '8px',
  },
  tablecell: {
    fontSize: 8,
    padding: '4px',
    textAlign: 'center',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
  },
  datatablecell: {
    fontSize: 8,
    padding: '4px',
    textAlign: 'center',
    overflowWrap: 'break-word',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
  },
});

export const Report = {
  employee: {
    fields: ['Όνομα', 'Επώνυμο', 'Ειδικότητα', 'Κόστος'],
    callbacks: [
      e => e.firstName,
      e => e.lastName,
      e =>
        e?.position
          ? resolveEmployeePosition(e.position)
          : e?.positionDescription
          ? e.positionDescription
          : 'Άλλο',
      e => `${formatAmount(parseFloat(e.rate))} €`,
    ],
  },
  jobs: {
    fields: ['Όνομα', 'Κόστος', 'Κατάσταση'],
    callbacks: [
      j => j.name,
      j => `${j.totalCost} €`,
      j => (j?.status ? resovleJobStatus(j.status) : '-'),
    ],
  },
};
