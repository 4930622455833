import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

import { signInUser, signUpUser, signOutUser, getAuthUser } from './auth';
import { addUser, addDocument, addDocumentWithId } from './create';
import { getDocument, getAllDocuments, getMultipleDocuments } from './read';
import { updateDocument } from './update';
import { deleteDocument } from './delete';

// Your web app's Firebase configuration
initializeApp({
  // Masterbudget - dev
  // apiKey: 'AIzaSyDgoWcx-cIj0V8oOMGWBUoRs5yPgELL3Tg',
  // authDomain: 'masterbudget-4605d.firebaseapp.com',
  // projectId: 'masterbudget-4605d',
  // storageBucket: 'masterbudget-4605d.appspot.com',
  // messagingSenderId: '687152986195',
  // appId: '1:687152986195:web:2e05f8f579aedb28382ea6',

  // Masterbudget - prod
  apiKey: 'AIzaSyA9rfThobhQgsz20gt-ctVb5GLIJtqzu64',
  authDomain: 'masterbudget-prod.firebaseapp.com',
  projectId: 'masterbudget-prod',
  storageBucket: 'masterbudget-prod.appspot.com',
  messagingSenderId: '440748226699',
  appId: '1:440748226699:web:e61fd1b5d2d420084bfc8e',

  // Aris - prod
  // apiKey: 'AIzaSyC0J-XGv9qdnx75odjsCRlvEztF-daZjag',
  // authDomain: 'master-budget-40840.firebaseapp.com',
  // projectId: 'master-budget-40840',
  // storageBucket: 'master-budget-40840.appspot.com',
  // messagingSenderId: '79275414507',
  // appId: '1:79275414507:web:7b86740cb75066e645ffae',

  // Aris - dev
  // apiKey: 'AIzaSyChaWrASOTj-LMzUwhddRo4aqasR_HwLP4',
  // authDomain: 'master-budget-develop.firebaseapp.com',
  // projectId: 'master-budget-develop',
  // storageBucket: 'master-budget-develop.appspot.com',
  // messagingSenderId: '84732465998',
  // appId: '1:84732465998:web:bdc01f73819c2f329f841f',
});

// Initialize Firebase
const db = getFirestore();

export {
  db,
  addDocument,
  addDocumentWithId,
  updateDocument,
  getDocument,
  getMultipleDocuments,
  getAllDocuments,
  addUser,
  signUpUser,
  signInUser,
  signOutUser,
  getAuthUser,
  deleteDocument,
};
