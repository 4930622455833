import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';
import { addUser } from './create';
import { getDocument } from './read';

const signUpUser = (email, password) => {
  const auth = getAuth();
  createUserWithEmailAndPassword(auth, email, password)
    .then(userCredential => {
      // Signed Up
      const user = userCredential.user;
      addUser('users', user.uid, 'Aris', 'Alexopoulos', 'male');
    })
    .catch(error => {
      console.log(error);
    });
};

const signInUser = async ({ email, password }) => {
  const auth = getAuth();
  const user = await signInWithEmailAndPassword(auth, email, password)
    .then(userCredential => {
      // Signed in
      const user = userCredential.user;
      localStorage.setItem('token', `Bearer ${user.accessToken}`);
      return user;
      // window.location.reload();
    })
    .catch(error => {
      console.log(error);
    });
  return user;
};

const signOutUser = callback => {
  const auth = getAuth();
  signOut(auth)
    .then(() => {
      localStorage.removeItem('token');
      callback();
      window.location.reload();
      // Sign-out successful.
    })
    .catch(error => {
      console.log(error);
    });
};

const getAuthUser = callback => {
  const auth = getAuth();
  onAuthStateChanged(auth, user => {
    if (user) {
      const { uid, accessToken, email } = user;
      getDocument('users', uid).then(response => {
        const { name, lastname, admin, accessRights } = response;
        callback({
          uid,
          accessToken,
          email,
          name,
          lastname,
          admin,
          accessRights,
        });
      });
    }
  });
};

export { signInUser, signUpUser, signOutUser, getAuthUser };
