export const filterOptions = [
  { label: 'Όνομα', name: 'name', type: 'text', value: 1 },
  {
    label: 'Κατάσταση',
    name: 'status',
    type: 'radio',
    value: 2,
    options: [
      { value: 1, label: 'Σχεδιασμός' },
      { value: 2, label: 'Υλοποίηση' },
      { value: 3, label: 'Ολοκλήρωση' },
    ],
  },
];
