import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import toLower from 'lodash/toLower';
import includes from 'lodash/includes';
import { useNavigate } from 'react-router-dom';

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import {
  Filters,
  Fab,
  Loading,
  CustomTable,
  FSDialog,
  ReportPDFViewer,
  ReportPDFDocument,
} from 'components';

import { ReportType } from 'components/ReportPDFDocument';

import {
  getEmployees,
  setEmployeeToEdit,
  clearEmployeeFields,
  setSelectedEmployee,
  deleteEmployee,
} from 'store/employees/actions';
import { getJobs, editJob } from 'store/jobs/actions';
import { fields as columns } from './fields';
import { filterOptions } from './content';
import { EmployeeDialog } from './components';
import { formattedNumber, localeSort } from 'utils';

const Employees = () => {
  const [selectedFilter, setSelectedFilter] = useState(filterOptions[0]);
  const [filteredEmployees, setEmployees] = useState([]);
  const [openFullScreenDialog, setOpenFullScreenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [editEmployee, setEditEmployee] = useState(false);
  const dispatch = useDispatch();
  const employees = useSelector(state => state.employees.employees);
  const jobs = useSelector(state => state.jobs.jobs);
  const loading = useSelector(state => state.employees.loading);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getEmployees());
    dispatch(getJobs());
  }, [dispatch]);

  useEffect(() => {
    const cloneEmployees = [...employees];
    const sortedEmployees = localeSort(cloneEmployees, 'lastName');
    setEmployees(sortedEmployees);
  }, [employees]);

  const renderColumnValue = (value, column, row) => {
    const { component, name, options, type } = column;
    switch (component) {
      case 'checkbox':
        return value ? 'Ναι' : 'Όχι';
      case 'text':
        return name === 'name'
          ? `${row.firstName} ${row.lastName}`
          : type === 'number'
          ? formattedNumber(value)
          : value;
      case 'select':
        return (
          row.positionDescription || options.find(o => o.value === value).label
        );
      default:
        return value;
    }
  };

  const handleDelete = (row, callback) => {
    const updatedJobs = jobs.map(job =>
      job.employees && !isEmpty(job.employees)
        ? {
            ...job,
            employees: job.employees.filter(em => em.id !== row.id),
          }
        : job
    );

    updatedJobs.map(job => dispatch(editJob({ ...job, jobId: job.id })));
    dispatch(deleteEmployee({ id: row.id, callback: () => callback(false) }));
  };

  const onChange = event => {
    const filter = filterOptions.find(
      opt => opt.value === selectedFilter.value
    );
    const newEmployees = employees.filter(em =>
      includes(toLower(em[filter.name]), event.target.value)
    );
    const sortedEmployees = localeSort(newEmployees, 'lastName');
    setEmployees(sortedEmployees);
  };

  return (
    <Fragment>
      {loading ? (
        <Loading />
      ) : isEmpty(employees) ? (
        <>
          <div className="d-flex align-items-center justify-content-between mt-2">
            <Fab
              onClick={() =>
                navigate('/', {
                  replace: true,
                })
              }
              title="Πίσω"
              icon={<ArrowBackRoundedIcon />}
            />
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center height-90vh">
            <div className="mb-2">Προσθέστε έναν εργαζόμενο</div>
            <Fab onClick={() => setOpen(true)} />
          </div>
        </>
      ) : (
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <Fab
              onClick={() =>
                navigate('/', {
                  replace: true,
                })
              }
              title="Πίσω"
              icon={<ArrowBackRoundedIcon />}
            />
            <Filters
              filterOptions={filterOptions}
              selectedFilter={selectedFilter}
              setSelectedFilter={filter => {
                setEmployees(employees);
                setSelectedFilter(filter);
              }}
              onChange={onChange}
            />
            <div className="d-flex align-items-center">
              <Fab
                onClick={() => {
                  setOpen(true);
                  setEditEmployee(false);
                  dispatch(clearEmployeeFields());
                }}
              />
              <Fab
                onClick={() => setOpenFullScreenDialog(true)}
                title="Εξαγωγή ως PDF"
                icon={<PictureAsPdfIcon fontSize="medium" />}
              />
            </div>
          </div>
          <CustomTable
            rows={filteredEmployees}
            columns={columns.filter(column => !column.hiddenFromTable)}
            renderColumnValue={renderColumnValue}
            withEdit={() => true}
            handleEdit={row => {
              setOpen(true);
              setEditEmployee(true);
              dispatch(setEmployeeToEdit(row));
            }}
            handleDelete={(row, callback) => handleDelete(row, callback)}
            handleNavigate={row => {
              navigate(`/employee/${row.id}`, {
                replace: true,
              });
              dispatch(setSelectedEmployee(row));
            }}
          />
        </div>
      )}
      <EmployeeDialog
        open={open}
        setOpen={setOpen}
        edit={editEmployee}
        setEditEmployee={setEditEmployee}
      />
      <FSDialog
        title="Έργα - Εξαγωγή σε PDF"
        open={openFullScreenDialog}
        closeHandler={() => setOpenFullScreenDialog(false)}
      >
        <ReportPDFViewer>
          <ReportPDFDocument
            type={ReportType.employees.type}
            data={filteredEmployees}
          />
        </ReportPDFViewer>
      </FSDialog>
    </Fragment>
  );
};

export default Employees;
