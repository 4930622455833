import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { formattedNumber, localeSort } from 'utils';
import { ContractDialog } from './components';
import { CustomTable } from 'components';
import { setSelectedEmployee } from 'store/employees/actions';

const SelectedEmployees = ({
  selectedJob,
  updateJob,
  updateEmployee,
  getEmployees,
  onSubmit,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const dispatch = useDispatch();
  const columns = [
    { label: 'Ονοματεπώνυμο', name: 'name', withNavigate: true },
    { label: 'Ανθρωποχρόνος', name: 'totalOccupation' },
    { label: 'Συνολικό κόστος', name: 'totalCost' },
    { label: 'Σύμβαση', name: 'hasContract' },
  ];

  const renderCellValue = (em, column) => {
    if (column.name === 'name') {
      return `${em.lastName} ${em.firstName}`;
    } else if (column.name === 'hasContract') {
      return em.hasContract ? 'ΕΝΕΡΓΗ' : 'ΑΝΕΝΕΡΓΗ';
    } else if (column.name === 'totalCost') {
      return formattedNumber(em.totalCost);
    } else {
      return em[column.name] || '-';
    }
  };

  const handleDelete = (row, callback) => {
    const employees = selectedJob.employees.filter(em => em.id !== row.id);
    const activeJobs = row.activeJobs.filter(j => j.id !== selectedJob.id);
    const externalEmployees = selectedJob.externalEmployees
      ? selectedJob.externalEmployees.filter(em => em.id !== row.id)
      : [];

    dispatch(
      updateEmployee({
        documentId: row.id,
        activeJobs,
        callback: () => {
          dispatch(getEmployees());
          callback(false);
        },
      })
    );
    dispatch(
      updateJob({
        documentId: selectedJob.id,
        employees,
        externalEmployees,
      })
    );
  };

  const handleEdit = row => {
    setOpen(true);
    setSelectedRow(row);
  };

  const rows = () => {
    const externals = selectedJob.externalEmployees || [];
    const merged = [...selectedJob.employees, ...externals];
    const sorted = localeSort(merged, 'lastName');
    return sorted;
  };

  return (
    <>
      <CustomTable
        rows={rows()}
        columns={columns}
        renderColumnValue={(_, column, row) => renderCellValue(row, column)}
        handleDelete={(row, callback) => handleDelete(row, callback)}
        withEdit={row => !row.hasContract}
        handleEdit={row => handleEdit(row)}
        handleNavigate={row => {
          navigate(`/employee/${row.id}`, {
            replace: true,
          });
          dispatch(setSelectedEmployee(row));
        }}
        maxHeight={300}
      />
      <ContractDialog
        open={open}
        setOpen={setOpen}
        row={selectedRow}
        onSubmit={() => onSubmit(selectedRow)}
      />
    </>
  );
};

export default SelectedEmployees;
