import React from 'react';

import { formattedNumber } from 'utils';
import { Button } from 'components';

const Details = ({
  selectedJob,
  selectedEmployee,
  totalCost,
  onClick,
  disabled,
}) => {
  return (
    <div className="d-flex align-items-center justify-content-between flex-column">
      <div>
        {selectedEmployee.id && (
          <div className="d-flex flex-column">
            <div className="d-flex mb-1">
              <div>Συνολικό κόστος εργαζόμενου: &nbsp;</div>
              <div>{totalCost ? formattedNumber(totalCost) : '-'} €</div>
            </div>
            <Button label="ΚΑΤΑΧΩΡΗΣΗ" onClick={onClick} disabled={disabled} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Details;
