import React from 'react';
import { Document, Text, Page, Font } from '@react-pdf/renderer';

import { Table, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table';

import Comfortaa from 'fonts/Comfortaa/ComfortaaRegular.ttf';

import { calculateJobTotalCosts } from 'views/job/utils';

import {
  resolveJobEmployees,
  resolveJobTotalCost,
  formatAmount,
  resolveStatus,
  resolveJobConsultings,
} from './utils';

import { styles, EmployeesTable } from './config';

Font.register({
  family: 'Comfortaa',
  src: Comfortaa,
});

const JobPDFReport = ({
  selectedJob,
  appName = 'Master Budget',
  companyName = 'ΝΕΑ ΜΗΤΡΟΠΟΛΙΤΙΚΗ ΑΤΤΙΚΗ',
}) => {
  const jobId = selectedJob.id;
  const jobEmployees = [
    EmployeesTable.fields,
    ...resolveJobEmployees(selectedJob),
  ];
  const jobTotals = resolveJobTotalCost(
    selectedJob?.restExpenses
      ? {
          restExpenses: formatAmount(selectedJob.restExpenses),
          ...calculateJobTotalCosts(selectedJob),
        }
      : calculateJobTotalCosts(selectedJob)
  );
  const jobConsultings = resolveJobConsultings(selectedJob);
  return (
    <Document
      style={styles.document}
      title={`MasterBudget | ${selectedJob.name}`}
    >
      <Page size="A4" style={styles.page} orientation="portrait">
        <Text style={styles.title}>{`${appName} | ${companyName}`}</Text>
        <Text style={styles.subtitle}>{` Έργο: ${selectedJob.name}`}</Text>
        <Text style={styles.subtitle}>
          {`Κατάσταση: ${resolveStatus(selectedJob.status)}`}
        </Text>
        {jobTotals && (
          <>
            <Text style={styles.sectiontitle}>
              {'Συγκεντρωτικά στοιχεία έργου:'}
            </Text>
            <Table data={jobTotals}>
              <TableBody>
                <DataTableCell
                  style={{ ...styles.datatablecell, textAlign: 'right' }}
                  getContent={c => c.name}
                />
                <DataTableCell
                  style={{ ...styles.datatablecell, textAlign: 'left' }}
                  getContent={c => `${c.value} €`}
                />
              </TableBody>
            </Table>
          </>
        )}
        {jobEmployees && jobEmployees.length > 0 && (
          <>
            <Text style={styles.sectiontitle}>{'Εργαζόμενοι έργου:'}</Text>
            <Table data={jobEmployees}>
              <TableBody>
                <DataTableCell
                  style={styles.datatablecell}
                  getContent={c =>
                    c?.fullNameHeader ? c.fullNameHeader : c?.fullName
                  }
                />
                <DataTableCell
                  style={styles.datatablecell}
                  getContent={c => {
                    if (c?.occupationHeader) return c.occupationHeader;
                    const occupation = c.activeJobs.filter(
                      j => j.id === jobId
                    )[0].occupation;
                    return formatAmount(
                      Object.values(occupation).reduce(
                        (accum, curr) =>
                          (accum += isNaN(parseFloat(curr))
                            ? 0.0
                            : parseFloat(curr)),
                        0.0
                      )
                    );
                  }}
                />
                <DataTableCell
                  style={styles.datatablecell}
                  getContent={c => {
                    if (c?.totalCostHeader) return c.totalCostHeader;
                    const occupation = c.activeJobs.filter(
                      j => j.id === jobId
                    )[0].occupation;
                    const totalOccupation = Object.values(occupation).reduce(
                      (accum, curr) =>
                        (accum += isNaN(parseFloat(curr))
                          ? 0.0
                          : parseFloat(curr)),
                      0.0
                    );
                    return `${formatAmount(
                      Math.trunc(totalOccupation * c.rate)
                    )} €`;
                  }}
                />
                <DataTableCell
                  style={styles.datatablecell}
                  getContent={c =>
                    c?.contractHeader
                      ? c.contractHeader
                      : c?.hasContract
                      ? 'ΕΝΕΡΓΗ'
                      : 'ΑΝΕΝΕΡΓΗ'
                  }
                />
              </TableBody>
            </Table>
          </>
        )}
        {jobConsultings && (
          <>
            <Text style={styles.sectiontitle}>{'Εξωτερικές Αναθέσεις:'}</Text>
            <Table data={jobConsultings}>
              <TableBody>
                <DataTableCell
                  style={{ ...styles.datatablecell, textAlign: 'left' }}
                  getContent={c => c.description}
                />
                <DataTableCell
                  style={{ ...styles.datatablecell, textAlign: 'left' }}
                  getContent={c =>
                    c.amount === 'Ποσό'
                      ? c.amount
                      : `${formatAmount(c.amount)} €`
                  }
                />
              </TableBody>
            </Table>
          </>
        )}
      </Page>
    </Document>
  );
};

export default JobPDFReport;
