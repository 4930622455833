import React from 'react';
import Divider from '@mui/material/Divider';

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="d-flex align-items-center">
        <div className="footer-container__title cursor-pointer">
          Πολιτική Cookies
        </div>
        <Divider orientation="vertical" flexItem sx={{ marginX: '8px' }} />
        <div className="footer-container__title cursor-pointer">
          Όροι χρήσης 
        </div>
        <Divider orientation="vertical" flexItem sx={{ marginX: '8px' }} />
        <div className="footer-container__title">
          Copyright &#169; TRC Consultants
        </div>
      </div>
    </div>
  );
};

export default Footer;
