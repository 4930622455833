export const resolveEmployeePosition = position => {
  switch (position) {
    case 1:
      return 'Μηχανικός';
    case 2:
      return 'Διοικητικός';
    case 3:
      return 'Νομικός';
    case 4:
      return 'Άλλο';
    default:
      return '-';
  }
};

export const resovleJobStatus = jobStatus => {
  switch (jobStatus) {
    case 1:
      return 'Σχεδιασμός';
    case 2:
      return 'Υλοποίηση';
    case 3:
      return 'Ολοκλήρωση';
    default:
      return '-';
  }
};
