import { actionTypes } from 'utils';

export const types = {
  ...actionTypes('GET_CONSULTINGS'),
  ...actionTypes('UPDATE_CONSULTING_FIELD'),
  ...actionTypes('ADD_CONSULTING'),
  ...actionTypes('CLEAR_CONSULTING_FIELDS'),
  ...actionTypes('SET_CONSULTING_TO_EDIT'),
  ...actionTypes('EDIT_CONSULTING'),
  ...actionTypes('DELETE_CONSULTING'),
};
