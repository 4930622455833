import React from 'react';
import { useDispatch } from 'react-redux';
import toNumber from 'lodash/toNumber';
import size from 'lodash/size';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import get from 'lodash/get';

import { CustomDialog } from 'components';
import { Calendar, ChooseEmployee } from 'views/job/components';
import { calculateMonths, calculateTotals } from 'views/job/utils';

const DateDialog = ({
  open,
  selectedJob,
  setJobEmployee,
  employees,
  initialJob,
  selectedEmployee,
  handleCancel,
  editJob,
  editEmployee,
  updateJobField,
  loading,
}) => {
  const dispatch = useDispatch();

  const handleSubmit = () => {
    const months = calculateMonths({ selectedJob });

    let newOccupation = {};

    const employeesWithNewOccupation = selectedJob.employees.map(em => {
      months.map(
        m =>
          (newOccupation = {
            ...newOccupation,
            [m.value]: em.occupation[m.value],
          })
      );

      const filteredOccupation = pickBy(newOccupation, identity);

      const newActiveJobs = em.activeJobs.map(j =>
        j.id === selectedJob.id ? { ...j, occupation: filteredOccupation } : j
      );
      const totalCost = calculateTotals({
        selectedEmployee: {
          ...em,
          occupation: filteredOccupation,
        },
      }).totalCost;
      const totalOccupation = calculateTotals({
        selectedEmployee: {
          ...em,
          occupation: filteredOccupation,
        },
      }).totalOccupation;
      return {
        ...em,
        occupation: filteredOccupation,
        activeJobs: newActiveJobs,
        totalCost,
        totalOccupation,
      };
    });

    employeesWithNewOccupation.map(em =>
      dispatch(
        editEmployee({
          employeeId: em.id,
          activeJobs: em.activeJobs,
        })
      )
    );

    dispatch(
      editJob({
        ...selectedJob,
        jobId: selectedJob.id,
        employees: employeesWithNewOccupation,
      })
    );
  };

  const handleDisabled = () => {
    return loading;
  };

  const dialogActions = [
    {
      label: 'ΑΚΥΡΩΣΗ',
      type: 'secondary',
      disabled: loading,
      onClick: () => {
        handleCancel();
      },
    },
    {
      label: 'ΕΠΕΞΕΡΓΑΣΙΑ',
      type: 'primary',
      disabled: handleDisabled(),
      onClick: () => {
        handleSubmit();
      },
    },
  ];

  const renderOccupationLeft = () => {
    const months = calculateMonths({ selectedJob });
    const initialMonths = calculateMonths({ selectedJob: initialJob });
    const initialEmployee = employees.find(em => em.id === selectedEmployee.id);
    const occupationOfInitialEmployee = get(
      initialEmployee.activeJobs.find(j => j.id === selectedJob.id),
      'occupation',
      {}
    );
    let newTotalOccupation = 0;
    let previousOccupation = 0;
    if (initialEmployee) {
      months.map(
        m =>
          (newTotalOccupation =
            newTotalOccupation +
            toNumber(selectedEmployee.occupation[m.value] || 0))
      );
      initialMonths.map(
        m =>
          (previousOccupation =
            previousOccupation +
            toNumber(occupationOfInitialEmployee[m.value] || 0))
      );
    }

    return (previousOccupation - newTotalOccupation).toFixed(2);
  };

  const updateJobEmployees = data => {
    return selectedJob.employees.map(em =>
      em.id === selectedEmployee.id
        ? {
            ...em,
            ...data,
          }
        : em
    );
  };

  return (
    <CustomDialog
      open={open}
      setOpen={handleCancel}
      title={'Επεξεργασία υπαλλήλων'}
      actions={dialogActions}
    >
      <div className="date-dialog-container">
        <div className="d-flex font-14 mb-1">
          <div>Έργο:&nbsp;</div>
          <div className="text-semibold">{selectedJob.name}</div>
        </div>
        <div className="d-flex font-14">
          <div>Αριθμός εργαζομένων:&nbsp;</div>
          <div className="text-semibold font-14">
            {size(selectedJob.employees)}
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-between mt-3 mb-3 choose-employee">
          <ChooseEmployee
            selectedJob={selectedJob}
            setJobEmployee={setJobEmployee}
            employees={selectedJob.employees}
          />
          {selectedEmployee.activeJobs && (
            <div className="font-14 d-flex">
              <div>Υπόλοιπο:&nbsp;</div>
              <div className="text-semibold">{renderOccupationLeft()}</div>
            </div>
          )}
        </div>
        <div className="date-dialog-container__calendar">
          <Calendar
            selectedJob={selectedJob}
            setEmployee={data => {
              dispatch(setJobEmployee(data));
              dispatch(
                updateJobField({
                  key: 'employees',
                  value: updateJobEmployees(data),
                })
              );
            }}
          />
        </div>
      </div>
    </CustomDialog>
  );
};

export default DateDialog;
