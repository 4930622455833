import { employeePositions } from 'utils/constants';

export const filterOptions = [
  {
    label: 'Όνομα',
    name: 'fullName',
    type: 'text',
    value: 1,
  },
  {
    label: 'Ειδικότητα',
    name: 'position',
    value: 2,
    options: employeePositions,
    type: 'radio',
  },
  {
    label: 'Εξωτερικός',
    name: 'isExternal',
    value: 3,
    options: [
      { label: 'Ναι', value: true },
      { label: 'Όχι', value: false },
    ],
    type: 'radio',
  },
];
