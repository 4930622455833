export const fields = [
  {
    label: 'Όνομα έργου',
    name: 'name',
    component: 'text',
    required: true,
    withNavigate: true,
  },
  {
    label: 'Έναρξη',
    name: 'startDate',
    component: 'datepicker',
    required: true,
  },
  { label: 'Λήξη', name: 'endDate', component: 'datepicker', required: true },
  {
    label: 'Εκτιμώμενο κόστος ανθρωποχρόνου (€)',
    name: 'netCost',
    component: 'text',
    type: 'number',
    required: true,
  },
  {
    label: 'Συνολικό κόστος (€)',
    name: 'totalCost',
    component: 'text',
    type: 'number',
    hiddenFromDialog: true,
  },
  {
    label: 'Κόστος εσωτερικών (€)',
    name: 'internalEmployeesCost',
    component: 'text',
    type: 'number',
    hiddenFromDialog: true,
  },
  {
    label: 'Έμμεσες δαπάνες (%)',
    name: 'expenses',
    component: 'text',
    type: 'percentage',
  },
  {
    label: 'Λοιπά κόστη (€)',
    name: 'restExpenses',
    component: 'text',
    type: 'number',
  },
  {
    label: 'ΦΠΑ (%)',
    name: 'vat',
    component: 'select',
    options: [
      { value: '0', label: '0%' },
      { value: '0.06', label: '6%' },
      { value: '0.13', label: '13%' },
      { value: '0.24', label: '24%' },
    ],
    required: true,
    type: 'percentage',
    hiddenFromTable: true,
  },
  {
    label: 'Φορέας χρηματοδότησης',
    name: 'beneficiary',
    component: 'text',
    required: true,
  },
  {
    label: 'Κατάσταση',
    name: 'status',
    component: 'select',
    options: [
      { value: 1, label: 'Σχεδιασμός' },
      { value: 2, label: 'Υλοποίηση' },
      { value: 3, label: 'Ολοκλήρωση' },
    ],
  },
];
