import { employeePositions } from 'utils/constants';

export const fields = [
  {
    label: 'Όνομα',
    name: 'firstName',
    component: 'text',
    required: true,
    hiddenFromTable: true,
  },
  {
    label: 'Επώνυμο',
    name: 'lastName',
    component: 'text',
    required: true,
    hiddenFromTable: true,
  },
  {
    label: 'Όνομα',
    name: 'fullName',
    component: 'text',
    withNavigate: true,
    hiddenFromDialog: true,
  },
  {
    label: 'Ειδικότητα',
    name: 'position',
    component: 'select',
    options: employeePositions,
    innerFields: [
      {
        label: 'Περιγραφή ειδικότητας',
        name: 'positionDescription',
        component: 'text',
      },
    ],
    required: true,
  },
  {
    label: 'Κόστος',
    name: 'rate',
    component: 'text',
    required: true,
    type: 'number',
  },
  {
    label: 'ΑΦΜ',
    name: 'taxNumber',
    component: 'text',
    required: true,
    maxLength: 9,
    errorMessage: 'Παρακαλώ εισάγετε έγκυρο ΑΦΜ',
  },
  { label: 'Εξωτερικός', name: 'isExternal', component: 'checkbox' },
  { label: 'Overbooking', name: 'overbooking', component: 'checkbox' },
];
