import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import toLower from 'lodash/toLower';
import includes from 'lodash/includes';
import toNumber from 'lodash/toNumber';
import cloneDeep from 'lodash/cloneDeep';
import { useNavigate } from 'react-router-dom';

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import {
  Filters,
  Loading,
  CustomTable,
  FSDialog,
  ReportPDFViewer,
  ReportPDFDocument,
  Fab,
} from 'components';

import { ReportType } from 'components/ReportPDFDocument';

import {
  getConsultings,
  setConsultingToEdit,
  deleteConsulting,
} from 'store/consultings/actions';

import { getJobs } from 'store/jobs/actions';

import { deleteConsultingFromJob } from 'store/jobs/actions';

import { fields as columns } from './fields';
import { filterOptions } from './content';
import { ConsultingDialog } from './components';
import { formattedNumber, localeSort } from 'utils';

const Consultings = () => {
  const [open, setOpen] = useState(false);
  const [openFullScreenDialog, setOpenFullScreenDialog] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(filterOptions[0]);
  const [editConsulting, setEditConsulting] = useState(false);
  const [filteredConsultings, setConsultings] = useState([]);
  const dispatch = useDispatch();
  const consultings = useSelector(state => state.consultings.consultings);
  const jobs = useSelector(state => state.jobs.jobs);
  const loading = useSelector(state => state.consultings.loading);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getConsultings());
    dispatch(getJobs());
  }, [dispatch]);

  useEffect(() => {
    const clonedConsultings = [...consultings];
    const sortedConsultings = localeSort(clonedConsultings, 'description');
    setConsultings(sortedConsultings);
  }, [consultings]);

  const onChange = event => {
    const filter = filterOptions.find(
      opt => opt.value === selectedFilter.value
    );
    const newConsultings = consultings.filter(con =>
      includes(toLower(con[filter.name]), event.target.value)
    );
    const sorted = localeSort(newConsultings, 'description');
    setConsultings(sorted);
  };

  const renderColumnValue = (value, column) => {
    if (column.name === 'job') {
      const job = value?.id ? jobs.find(j => j.id === value.id) : null;
      return job ? job.name : '';
    } else if (column.component === 'select') {
      if (value) {
        const option = column.options.find(opt => opt.value === value);
        return option ? option.label : '-';
      } else {
        return '-';
      }
    } else if (column.type === 'number') {
      return formattedNumber(value);
    } else {
      return value;
    }
  };

  const renderFooterValue = column => {
    switch (column.name) {
      case 'description':
        return 'Σύνολο';
      case 'amount':
        let total = 0;
        filteredConsultings.map(c => (total = total + toNumber(c.amount)));
        return formattedNumber(total);
      default:
        return '-';
    }
  };

  const prepareFilteredConsultingsForPDF = consultings => {
    const consultingsCopy = cloneDeep(consultings); // deep copy
    let totalAmount = 0;
    const updatedConsultings = consultingsCopy.map(c => {
      totalAmount += parseFloat(c?.amount);
      const jobName = jobs.find(j => c?.job?.id && c.job.id === j.id)?.name;
      if (c?.job?.name) c.job.name = jobName ? jobName : '-';
      return c;
    });
    updatedConsultings.push({
      description: 'Σύνολο',
      amount: totalAmount,
      job: { name: '-' },
      taxNumber: '-',
      name: '-',
      operation: '-',
      address: '-',
    });
    return updatedConsultings;
  };

  const handleDelete = (row, callback) => {
    if (row?.job?.id && jobs.filter(j => j.id === row.job.id).length) {
      const updatedConsultings = jobs
        .filter(j => j.id === row.job.id)[0]
        .consultings.filter(c => c.id !== row.id);
      dispatch(deleteConsultingFromJob(row.id, row.job.id, updatedConsultings));
    } else {
      dispatch(deleteConsulting({ id: row.id }));
    }
    dispatch(getConsultings());
    callback(false);
  };

  return (
    <Fragment>
      {loading ? (
        <Loading />
      ) : isEmpty(consultings) ? (
        <>
          <div className="d-flex align-items-center justify-content-between">
            <Fab
              onClick={() =>
                navigate('/', {
                  replace: true,
                })
              }
              title="Πίσω"
              icon={<ArrowBackRoundedIcon />}
            />
            <div className="d-flex align-items-center justify-content-between">
              <Fab
                onClick={() => {
                  setOpen(true);
                  setEditConsulting(false);
                }}
                title="Προσθήκη Νέας Ανάθεσης"
              />
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between mt-3"></div>
          <div className="d-flex flex-column align-items-center justify-content-center height-90vh">
            <div className="mb-2">Δεν υπάρχουν αναθέσεις</div>
          </div>
        </>
      ) : (
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <Fab
              onClick={() =>
                navigate('/', {
                  replace: true,
                })
              }
              title="Πίσω"
              icon={<ArrowBackRoundedIcon />}
            />
            <Filters
              filterOptions={filterOptions}
              selectedFilter={selectedFilter}
              setSelectedFilter={filter => {
                setConsultings(consultings);
                setSelectedFilter(filter);
              }}
              onChange={onChange}
            />
            <div className="d-flex align-items-center justify-content-between">
              <Fab
                onClick={() => {
                  setOpen(true);
                  setEditConsulting(false);
                }}
                title="Προσθήκη Νέας Ανάθεσης"
              />
              <Fab
                onClick={() => setOpenFullScreenDialog(true)}
                title="Εξαγωγή ως PDF"
                icon={<PictureAsPdfIcon fontSize="medium" />}
              />
            </div>
          </div>
          <CustomTable
            rows={filteredConsultings}
            columns={columns}
            renderColumnValue={(value, column, row) =>
              renderColumnValue(value, column, row)
            }
            handleEdit={row => {
              setOpen(true);
              setEditConsulting(true);
              dispatch(setConsultingToEdit(row));
            }}
            handleDelete={(row, callback) => {
              handleDelete(row, callback);
            }}
            withEdit={() => true}
            withFooter
            renderFooterValue={renderFooterValue}
          />
        </div>
      )}
      <ConsultingDialog open={open} setOpen={setOpen} edit={editConsulting} />
      <FSDialog
        title="Εξωτερικές Αναθέσεις - Εξαγωγή σε PDF"
        open={openFullScreenDialog}
        closeHandler={() => setOpenFullScreenDialog(false)}
      >
        <ReportPDFViewer>
          <ReportPDFDocument
            type={ReportType.consultings.type}
            data={prepareFilteredConsultingsForPDF(filteredConsultings)}
          />
        </ReportPDFViewer>
      </FSDialog>
    </Fragment>
  );
};

export default Consultings;
