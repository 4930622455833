import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialog = ({ title, open, closeHandler, children }) => {
  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={closeHandler}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography
              sx={{ ml: 1, flex: 1, color: 'white' }}
              variant="h6"
              component="div"
            >
              <PictureAsPdfIcon
                sx={{
                  verticalAlign: 'middle',
                  fontSize: '1.5rem',
                  marginRight: '10px',
                }}
              />
              {title}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeHandler}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {children}
      </Dialog>
    </>
  );
};

export default FullScreenDialog;
