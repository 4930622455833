import {
  Landing,
  Home,
  Jobs,
  Employees,
  Job,
  Employee,
  Consultings,
  Admin,
} from 'views';

import GroupIcon from '@mui/icons-material/Group';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BusinessIcon from '@mui/icons-material/Business';

export const routes = [
  {
    name: 'landing',
    path: '/',
    Element: Landing,
    requireAuth: false,
    usedOnSidebar: false,
  },
  {
    name: 'home',
    path: '/home',
    Element: Home,
    requireAuth: true,
    usedOnSidebar: false,
  },
  {
    name: 'jobs',
    path: '/jobs',
    label: 'Έργα',
    Element: Jobs,
    requireAuth: true,
    Icon: <AssignmentIcon />,
    usedOnSidebar: true,
  },
  {
    name: 'employees',
    path: '/employees',
    label: 'Εργαζόμενοι',
    Element: Employees,
    requireAuth: true,
    Icon: <GroupIcon />,
    usedOnSidebar: true,
  },
  {
    name: 'job',
    path: '/job/:id',
    label: 'Έργο',
    Element: Job,
    requireAuth: true,
    usedOnSidebar: false,
  },
  {
    name: 'employee',
    path: '/employee/:id',
    label: 'Εργαζόμενος',
    Element: Employee,
    requireAuth: true,
    usedOnSidebar: false,
  },
  {
    name: 'consulting',
    path: '/consulting',
    label: 'Εξωτερική ανάθεση',
    Element: Consultings,
    requireAuth: true,
    Icon: <BusinessIcon />,
    usedOnSidebar: true,
  },
  {
    name: 'administration',
    path: '/admin',
    label: 'Διαχείρηση',
    Element: Admin,
    requireAuth: true,
    usedOnSidebar: false,
  },
];
