import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';

import { Button } from 'components';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomDialog = ({
  open,
  setOpen,
  title,
  children,
  actions,
  actionColor = 'primary',
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="dialog-container">
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="custom-dialog"
        fullWidth
      >
        <DialogTitle>{title}</DialogTitle>

        <DialogContent>{children}</DialogContent>

        {isArray(actions) && !isEmpty(actions) && (
          <DialogActions className="dialog-container__actions">
            {actions.map((action, index) => (
              <Button
                key={index}
                onClick={action.onClick}
                variant={action.type === 'primary' ? 'contained' : 'text'}
                disabled={action.disabled}
                label={action.label}
                color={actionColor}
              />
            ))}
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

export default CustomDialog;
