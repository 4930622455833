import React from 'react';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Zoom from '@mui/material/Zoom';
import Tooltip from '@mui/material/Tooltip';

const FloatingActionButton = ({
  onClick,
  title = 'Προσθήκη',
  icon = <AddIcon />,
}) => {
  return (
    <Tooltip TransitionComponent={Zoom} title={title} arrow>
      <Fab
        size="small"
        color="primary"
        aria-label="add"
        className="floating-button"
        onClick={onClick}
        sx={{ margin: '1rem' }}
      >
        {icon}
      </Fab>
    </Tooltip>
  );
};

export default FloatingActionButton;
