import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { useNavigate } from 'react-router-dom';

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { Fab, FSDialog, ReportPDFViewer } from 'components';
import {
  getJob,
  getJobs,
  updateJob,
  updateRestJob,
  setJobEmployee,
  updateEmployee,
  clearState,
} from 'store/jobs/actions';
import { getEmployees } from 'store/employees/actions';
import {
  clearConsultingFields,
  editConsulting,
} from 'store/consultings/actions';
import {
  Calendar,
  SelectedEmployees,
  JobTotals,
  ExternalEmployee,
  ChooseEmployee,
  JobPDFReport,
  ConsultingJobLinkDialog,
} from './components';
import {
  handleSubmitDisabled,
  calculateTotals,
  onSubmit,
  startEmployeeContract,
  linkJobWithConsulting,
  unlinkJobFromConsulting,
} from './utils';
import { ConsultingDialog } from 'views/consultings/components';
import { resolveStatus } from './components/jobPdfReport/utils';

const Job = () => {
  const [open, setOpen] = useState(false);
  const [openFullScreenDialog, setOpenFullScreenDialog] = useState(false); // open fullscreen dialog of pdf report
  const [consultingOpen, setConsultingOpen] = useState(false);
  const [openConsultingLinkDialog, setOpenConsultingLinkDialog] =
    useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedJob = useSelector(state => state.jobs.selectedJob);
  const selectedEmployee = useSelector(state => state.jobs.selectedEmployee);
  const employees = useSelector(state => state.employees);
  const deletingConsulting = useSelector(
    state => state.jobs.deletingConsulting
  );
  const consultings = useSelector(state => state.consultings.consultings);
  const { id } = useParams();
  const isExternal = get(selectedEmployee, 'isExternal', false);
  useEffect(() => {
    isEmpty(selectedJob) && dispatch(getJob(id));
    isEmpty(employees.employees) && dispatch(getEmployees());
  }, [selectedJob, id, employees.employees, dispatch]);

  useEffect(() => () => dispatch(clearState()), [dispatch]);
  return (
    <div className="selected-job h-100">
      <div className="selected-job__upper-section">
        <div className="d-flex justify-content-center mb-1">
          <div>Επιλεγμένο έργο:&nbsp;</div>
          <div className="text-semibold">{selectedJob.name}</div>
        </div>
        {selectedJob?.status ? (
          <div className="d-flex justify-content-center mb-3">
            Κατάσταση:&nbsp;
            <div className="text-semibold">
              {resolveStatus(selectedJob.status)}
            </div>
          </div>
        ) : (
          ''
        )}
        <div className="d-flex align-items-center justify-content-between">
          <Fab
            onClick={() =>
              navigate('/jobs', {
                replace: true,
              })
            }
            title="Πίσω"
            icon={<ArrowBackRoundedIcon />}
          />
          <ChooseEmployee
            setJobEmployee={setJobEmployee}
            selectedJob={selectedJob}
            employees={employees.employees}
          />
          <div className="d-flex align-items-center">
            <Fab
              title={'Προσθήκη ανάθεσης'}
              onClick={() => {
                setOpenConsultingLinkDialog(true);
                dispatch(clearConsultingFields());
              }}
            />
            <Fab
              onClick={() => setOpenFullScreenDialog(true)}
              title="Εξαγωγή ως PDF"
              icon={<PictureAsPdfIcon fontSize="medium" />}
            />
          </div>
        </div>
        {selectedEmployee.id && (
          <div className="mt-3 d-flex flex-3">
            {isExternal ? (
              <ExternalEmployee
                selectedJob={selectedJob}
                selectedEmployee={selectedEmployee}
                setEmployee={data => dispatch(setJobEmployee(data))}
              />
            ) : (
              <Calendar
                selectedJob={selectedJob}
                setEmployee={data => dispatch(setJobEmployee(data))}
              />
            )}
          </div>
        )}
      </div>
      {selectedJob && (
        <JobTotals
          selectedJob={selectedJob}
          selectedEmployee={selectedEmployee}
          jobBudget={calculateTotals({ selectedEmployee }).totalCost}
          open={consultingOpen}
          setOpen={setConsultingOpen}
          onDeleteConsulting={(consultingId, updatedConsultings) => {
            unlinkJobFromConsulting(
              updateJob,
              editConsulting,
              dispatch,
              selectedJob,
              consultings.find(c => c.id === consultingId)
            );
          }}
          deleteDisabled={deletingConsulting}
          onClick={() =>
            onSubmit({
              selectedEmployee,
              selectedJob,
              updateEmployee,
              updateJob,
              updateRestJob,
              getEmployees,
              dispatch,
            })
          }
          disabled={handleSubmitDisabled({ selectedEmployee, selectedJob })}
        />
      )}
      {selectedJob
        ? (selectedJob.employees || selectedJob.externalEmployees) &&
          (!isEmpty(selectedJob.employees) ||
            !isEmpty(selectedJob.externalEmployees)) && (
            <div className="selected-job__bottom-section">
              <SelectedEmployees
                selectedJob={selectedJob}
                updateJob={updateJob}
                updateEmployee={updateEmployee}
                getEmployees={getEmployees}
                onSubmit={row =>
                  startEmployeeContract({
                    updateJob,
                    updateEmployee,
                    selectedJob,
                    selectedEmployee: row,
                    dispatch,
                  })
                }
              />
            </div>
          )
        : null}
      <ConsultingDialog
        open={open}
        setOpen={setOpen}
        selectedJob={selectedJob}
        updateJob={updateJob}
        linkWithJob
      />
      <FSDialog
        title={`Έργο ${selectedJob.name}  - Εξαγωγή σε PDF`}
        open={openFullScreenDialog}
        closeHandler={() => setOpenFullScreenDialog(false)}
      >
        <ReportPDFViewer>
          <JobPDFReport selectedJob={selectedJob} />
        </ReportPDFViewer>
      </FSDialog>
      <ConsultingJobLinkDialog
        open={openConsultingLinkDialog}
        closeHandler={() => setOpenConsultingLinkDialog(false)}
        handleSubmit={(selectedConsultingId, jobId) => {
          const selectedConsulting = consultings.find(
            c => c.id === selectedConsultingId
          );
          const asyncCallFunc = async () => {
            await linkJobWithConsulting(
              updateJob,
              editConsulting,
              dispatch,
              selectedJob,
              selectedConsulting
            );
            await dispatch(getJobs());
          };
          asyncCallFunc();
        }}
        selectedJob={selectedJob}
        consultings={consultings}
      />
    </div>
  );
};

export default Job;
