import { formatAmount } from 'utils';
import { resolveEmployeePosition } from 'views/employee/components/employeePdfReport/helpers';
import { StyleSheet } from '@react-pdf/renderer';

export const ReportType = {
  consultings: {
    type: 'consultings',
    title: 'Εξωτερικές Αναθέσεις',
    fields: [
      'Περιγραφή',
      'Ποσό',
      'Έργο',
      'ΑΦΜ',
      'Επωνυμία',
      'Δραστηριότητα',
      'Διεύθυνση',
      'Κατάσταση',
    ],
    callbacks: [
      r => r.description,
      r => `${formatAmount(r.amount)}`,
      r => (r?.job?.name ? r.job.name : '-'),
      r => (r?.taxNumber ? r.taxNumber : '-'),
      r => (r?.name ? r.name : '-'),
      r => (r?.operation ? r.operation : '-'),
      r => (r?.address ? r.address : '-'),
      r => (r?.status ? (r.status === 1 ? 'Ενεργή' : 'Ολοκλήρωση') : '-'),
    ],
  },
  jobs: {
    type: 'jobs',
    title: 'Έργα',
    fields: [
      'Όνομα έργου',
      'Έναρξη',
      'Λήξη',
      'Εκτιμώμενο κόστος ανθρωποχρόνου (€)',
      'Συνολικό κόστος (€)',
      'Κόστος εσωτερικών (€)',
      'Έμμεσες δαπάνες (%)',
      'Λοιπά κόστη (€)',
      'Φορέας χρηματοδότησης',
      'Κατάσταση',
    ],
    callbacks: [
      r => (r?.name ? r.name : '-'),
      r =>
        r?.startDate ? new Date(r.startDate).toLocaleDateString('el-GR') : '-',
      r => (r?.endDate ? new Date(r.endDate).toLocaleDateString('el-GR') : '-'),
      r => (r?.netCost ? `${formatAmount(r.netCost)}` : '-'),
      r => (r?.totalCost ? `${formatAmount(r.totalCost)}` : '-'),
      r => (r?.name ? r.name : '-'),
      r => (r?.name ? r.name : '-'),
      r => (r?.restExpenses ? `${formatAmount(r.restExpenses)}` : '-'),
      r => (r?.beneficiary ? r.beneficiary : '-'),
      r => (r?.status ? resolveEmployeePosition(r.status) : '-'),
    ],
  },
  employees: {
    type: 'employees',
    title: 'Εργαζόμενοι',
    fields: [
      'Όνομα',
      'Ειδικότητα',
      'Κόστος',
      'ΑΦΜ',
      'Εξωτερικός',
      'Overbooking',
    ],
    callbacks: [
      r => (r?.fullName ? r.fullName : '-'),
      r =>
        r?.position
          ? resolveEmployeePosition(r.position)
          : r?.positionDescription
          ? r.positionDescription
          : 'Άλλο',
      r => (r?.rate ? r.rate : '-'),
      r => (r?.taxNumber ? r.taxNumber : '-'),
      r => (r?.isExternal ? (r.isExternal ? 'Ναι' : 'Όχι') : '-'),
      r => (r?.overbooking ? 'Ναι' : 'Όχι'),
    ],
  },
};

export const styles = StyleSheet.create({
  document: {
    fontFamily: 'Comfortaa',
  },
  page: {
    padding: '30px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  title: {
    fontSize: 16,
    marginBottom: '10px',
  },
  tablecell: {
    fontSize: 8,
    padding: '4px',
    textAlign: 'center',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
  },
  datatablecell: {
    fontSize: 8,
    padding: '4px',
    textAlign: 'center',
    overflowWrap: 'break-word',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
  },
});
