export const formattedNumber = (value = 0) => {
  let parts = value.toString().replace('.', ',').split(',');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  // this check is enchanced for showing 2 decimals when the input has only one decimal. (e.g 5.7 will be converted to 5.70)
  // in case of multiple digits int decimal part of number round only the first two
  parts[1] =
    parts.length === 1
      ? '00'
      : Math.round(
          parseFloat(parts[1]) / 10 ** (parts[1].length - 2)
        ).toString();
  return parts.join(',');
};

export const localeSort = (list, attribute) =>
  list.sort((a, b) => a[attribute].localeCompare(b[attribute]));

export const formatAmount = amount =>
  new Intl.NumberFormat('de-DE', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
