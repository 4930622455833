import { actionTypes } from 'utils';

export const types = {
  ...actionTypes('UPDATE_EMPLOYEE_FIELD'),
  ...actionTypes('CLEAR_FIELDS'),
  ...actionTypes('ADD_EMPLOYEE'),
  ...actionTypes('GET_EMPLOYEES'),
  ...actionTypes('UPDATE_EMPLOYEE'),
  ...actionTypes('SET_EMPLOYEE_TO_EDIT'),
  ...actionTypes('EDIT_EMPLOYEE'),
  ...actionTypes('SET_SELECTED_EMPLOYEE'),
  ...actionTypes('GET_EMPLOYEE'),
  ...actionTypes('DELETE_EMPLOYEE'),
};
