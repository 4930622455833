import { actionTypes } from 'utils';

export const types = {
  ...actionTypes('UPDATE_JOB_FIELD'),
  ...actionTypes('CLEAR_FIELDS'),
  ...actionTypes('ADD_JOB'),
  ...actionTypes('GET_JOBS'),
  ...actionTypes('SET_SELECTED_JOB'),
  ...actionTypes('SET_JOB_EMPLOYEE'),
  ...actionTypes('UPDATE_EMPLOYEE'),
  ...actionTypes('GET_JOB'),
  ...actionTypes('UPDATE_JOB'),
  ...actionTypes('CLEAR_STATE'),
  ...actionTypes('SET_JOB_TO_EDIT'),
  ...actionTypes('EDIT_JOB'),
  ...actionTypes('DELETE_SELECTED_CONSULTING'),
  ...actionTypes('UPDATE_REST_JOB'),
  ...actionTypes('DELETE_JOB'),
};
