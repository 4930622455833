import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user/reducer';
import jobReducer from './jobs/reducer';
import employeesReducer from './employees/reducer';
import consultingsRecucer from './consultings/reducer';
import uiReducer from './ui/reducer';
import adminReducer from './admin/reducer';

export const store = configureStore({
  reducer: {
    user: userReducer,
    jobs: jobReducer,
    employees: employeesReducer,
    consultings: consultingsRecucer,
    ui: uiReducer,
    admin: adminReducer,
  },
});
