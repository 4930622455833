import { types } from './types';
import { getAllDocuments } from 'firebase';

export const getUsers = () => dispatch => {
  dispatch({ type: types.GET_USERS.START });
  getAllDocuments('users')
    .then(data => {
      dispatch({ type: types.GET_USERS.SUCCESS, payload: data });
    })
    .catch(error => dispatch({ type: types.GET_USERS.ERROR }));
};
