export const fields = [
  {
    label: 'Περιγραφή',
    name: 'description',
    component: 'text',
    mainField: true,
    required: true,
  },
  {
    label: 'Ποσό',
    name: 'amount',
    component: 'text',
    mainField: true,
    required: true,
    type: 'number',
  },
  { label: 'Έργο', name: 'job', component: 'text', hiddenFromTable: true },
  {
    label: 'ΑΦΜ',
    name: 'taxNumber',
    component: 'text',
    required: true,
    maxLength: 9,
    errorMessage: 'Παρακαλώ εισάγετε έγκυρο ΑΦΜ',
  },
  { label: 'Επωνυμία', name: 'name', component: 'text' },
  { label: 'Δραστηριότητα', name: 'operation', component: 'text' },
  { label: 'Διεύθυνση', name: 'address', component: 'text' },
  {
    label: 'Κατάσταση',
    name: 'status',
    component: 'select',
    options: [
      { value: 1, label: 'Ενεργή' },
      { value: 2, label: 'Ολοκλήρωση' },
    ],
  },
];
