import React from 'react';
import Input from '@mui/material/Input';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import { calculateOccupationLeft, calculateMonths } from 'views/job/utils';

const Calendar = ({ selectedJob, setEmployee }) => {
  const selectedEmployee = useSelector(state => state.jobs.selectedEmployee);
  const handleChange = (event, month) => {
    const value = event.target.value;
    const basicPayload = {
      id: selectedJob.id,
      name: selectedJob.name,
      occupation: { [month.value]: value },
    };
    setEmployee({
      occupation: {
        ...selectedEmployee.occupation,
        [month.value]: value,
      },
      activeJobs: isEmpty(selectedEmployee.activeJobs)
        ? [{ ...basicPayload }]
        : selectedEmployee.activeJobs.some(job => job.id === selectedJob.id)
        ? selectedEmployee.activeJobs.map(job =>
            job.id === selectedJob.id
              ? {
                  ...job,
                  occupation: {
                    ...job.occupation,
                    [month.value]: value,
                  },
                }
              : { ...job }
          )
        : [...selectedEmployee.activeJobs, { ...basicPayload }],
    });
  };

  return (
    <div className="d-flex align-items-center w-100">
      <div className="calendar-titles">
        <div>Διαθεσιμότητα</div>
        <div>Χρέωση</div>
      </div>
      <div className="selected-job__calendar">
        {calculateMonths({ selectedJob }).map((month, index) => {
          return (
            <div
              key={index}
              className="d-flex flex-column justify-content-between"
            >
              <div
                className={`calendar-month ${
                  calculateOccupationLeft({
                    month: month.value,
                    selectedEmployee,
                  }) < 0
                    ? 'error-month'
                    : ''
                }`}
              >
                {month.displayValue}
              </div>
              <div className="calendar-input with-border text-center">
                {calculateOccupationLeft({
                  month: month.value,
                  selectedEmployee,
                })}
              </div>
              <Input
                disableUnderline
                className="calendar-input"
                type="number"
                inputProps={{
                  min: 0,
                  max: 1,
                  step: 0.1,
                }}
                value={selectedEmployee.occupation[month.value] || ''}
                onChange={event => handleChange(event, month)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Calendar;
