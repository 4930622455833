import React from 'react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import { CustomDialog } from 'components';

const DeleteDialog = ({ title, open, setOpen, handleDelete, selectedRow }) => {
  const actions = [
    {
      label: 'ΑΚΥΡΩΣΗ',
      type: 'secondary',
      onClick: () => {
        setOpen(false);
      },
    },
    {
      label: 'ΔΙΑΓΡΑΦΗ',
      type: 'primary',
      onClick: () => {
        handleDelete({ setOpen: data => setOpen(data) });
      },
    },
  ];
  return (
    <CustomDialog
      open={open}
      title={title}
      setOpen={setOpen}
      actions={actions}
      actionColor={'error'}
    >
      <div className="flex-column align-items-center delete-dialog">
        <div className="delete-dialog__warning">
          <WarningAmberIcon sx={{ width: 24, height: 24 }} color={'error'} />
        </div>
        <div>Είσαι σίγουρος ότι θες να διαγράψεις την εγγραφή;</div>
      </div>
    </CustomDialog>
  );
};

export default DeleteDialog;
