import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

const DeleteRowButton = ({ onClick }) => {
  return (
    <td className="table-row-action table-row-delete" onClick={onClick}>
      <DeleteIcon color="error" sx={{ width: 18, height: 18 }} />
    </td>
  );
};

export default DeleteRowButton;
