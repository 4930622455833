import React from 'react';
import toNumber from 'lodash/toNumber';
import isEmpty from 'lodash/isEmpty';

import { SelectedConsultings } from './components';
import Details from '../details';
import { formattedNumber } from 'utils';
import { calculateJobTotalCosts } from 'views/job/utils';

const JobTotals = ({
  selectedJob,
  selectedEmployee,
  jobBudget,
  onClick,
  disabled,
  open,
  setOpen,
  onDeleteConsulting,
  deleteDisabled,
}) => {
  const summary = calculateJobTotalCosts(selectedJob);
  return (
    <div className="selected-job__totals">
      {selectedJob.employees && (
        <div>
          {selectedJob.consultings && !isEmpty(selectedJob.consultings) && (
            <div className="d-flex">
              <div
                className="totals-label consulting-clickable"
                onClick={() => setOpen(true)}
              >
                Εξωτερική ανάθεση
              </div>
              <div>{summary.totalConsulting} €</div>
            </div>
          )}
          <div className="d-flex">
            <div className="totals-label">Εσωτερικοί εργαζόμενοι: &nbsp;</div>
            <div>{summary.internalEmployeesCost} €</div>
          </div>
          <div className="d-flex">
            <div className="totals-label">Εξωτερικοί εργαζόμενοι: &nbsp;</div>
            <div>{summary.externalEmployeesCost} €</div>
          </div>
          <div className="d-flex">
            <div className="totals-label">Έμμεσες δαπάνες: &nbsp;</div>
            <div>{summary.totalExpenses || '-'} €</div>
          </div>
          <div className="d-flex">
            <div className="totals-label">Λοιπές δαπάνες: &nbsp;</div>
            <div>
              {selectedJob.restExpenses
                ? formattedNumber(selectedJob.restExpenses)
                : 0}{' '}
              €
            </div>
          </div>
          <div className="d-flex">
            <div className="totals-label">Καθαρό κόστος έργου: &nbsp;</div>
            <div>{summary.netCost} €</div>
          </div>
          <div className="d-flex">
            <div className="totals-label">ΦΠΑ: &nbsp;</div>
            <div>{summary.totalVat} €</div>
          </div>
          <div className="d-flex">
            <div className="totals-label">Σύνολο έργου: &nbsp;</div>
            <div>{summary.totalJobBudget} €</div>
          </div>
        </div>
      )}
      <Details
        selectedJob={selectedJob}
        selectedEmployee={selectedEmployee}
        totalCost={jobBudget}
        onClick={onClick}
        disabled={disabled}
      />
      {selectedJob.consultings && !isEmpty(selectedJob.consultings) && (
        <SelectedConsultings
          open={open}
          setOpen={setOpen}
          selectedJob={selectedJob}
          onDeleteConsulting={onDeleteConsulting}
          deleteDisabled={deleteDisabled}
        />
      )}
    </div>
  );
};

export default JobTotals;
