import React from 'react';
import EditIcon from '@mui/icons-material/Edit';

const EditRowButton = ({ onClick }) => {
  return (
    <td className="table-row-action table-row-edit" onClick={onClick}>
      <EditIcon color="primary" sx={{ width: 18, height: 18 }} />
    </td>
  );
};

export default EditRowButton;
