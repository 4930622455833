import cloneDeep from 'lodash/cloneDeep';

export const prepareFilteredJobsForPDF = jobs => {
  const jobsCopy = cloneDeep(jobs); // deep copy
  let totalCost = 0;
  let netCost = 0;
  jobsCopy.map(j => {
    totalCost += parseFloat(j.totalCost);
    netCost += parseFloat(j.netCost);
  });
  jobsCopy.push({
    name: 'Σύνολο',
    netCost: netCost,
    totalCost: totalCost,
  });
  return jobsCopy;
};
