import { types } from './types';

const INITIAL_STATE = {
  jobs: [],
  jobToCreate: {
    fields: {},
  },
  selectedJob: {},
  selectedEmployee: {
    id: '',
    rate: 0,
    firstName: '',
    lastName: '',
    totalCost: 0,
    totalOccupation: 0,
    occupation: {},
  },
  deletingConsulting: false,
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.UPDATE_JOB_FIELD.SUCCESS:
      return {
        ...state,
        jobToCreate: {
          ...state.jobToCreate,
          fields: {
            ...state.jobToCreate.fields,
            [payload.key]: payload.value,
          },
        },
      };
    case types.CLEAR_FIELDS.SUCCESS:
      return {
        ...state,
        jobToCreate: {
          fields: {},
        },
        selectedEmployee: INITIAL_STATE.selectedEmployee,
      };
    case types.ADD_JOB.START:
      return {
        ...state,
        jobToCreate: {
          ...state.jobToCreate,
          ...payload,
        },
      };
    case types.ADD_JOB.SUCCESS:
      return {
        ...state,
        jobToCreate: {
          ...state.jobToCreate,
          ...payload,
        },
      };
    case types.ADD_JOB.ERROR:
      return {
        ...state,
        jobToCreate: {
          ...state.jobToCreate,
          loading: false,
          success: false,
        },
      };
    case types.GET_JOBS.START:
      return {
        ...state,
        loading: true,
      };
    case types.GET_JOBS.SUCCESS:
      return {
        ...state,
        jobs: payload,
        loading: false,
      };
    case types.GET_JOBS.ERROR:
      return {
        ...state,
        loading: false,
      };
    case types.SET_SELECTED_JOB.SUCCESS:
      return {
        ...state,
        selectedJob: payload,
      };
    case types.SET_JOB_EMPLOYEE.SUCCESS:
      return {
        ...state,
        selectedEmployee: {
          ...state.selectedEmployee,
          ...payload,
        },
      };
    case types.GET_JOB.SUCCESS:
      return {
        ...state,
        selectedJob: payload,
      };
    case types.UPDATE_JOB.SUCCESS:
      return {
        ...state,
        selectedJob: {
          ...state.selectedJob,
          employees: payload.employees,
          consultings: payload.consultings
            ? payload.consultings
            : state.selectedJob.consultings
            ? [...state.selectedJob.consultings]
            : undefined,
          externalEmployees: payload.externalEmployees
            ? payload.externalEmployees
            : state.selectedJob.externalEmployees
            ? [...state.selectedJob.externalEmployees]
            : undefined,
        },
      };
    case types.UPDATE_EMPLOYEE.START:
      return {
        ...state,
        selectedEmployee: {
          ...state.selectedEmployee,
          loading: true,
        },
      };
    case types.UPDATE_EMPLOYEE.SUCCESS:
      return {
        ...state,
        selectedEmployee: {
          ...state.selectedEmployee,
          activeJobs: payload.activeJobs,
          loading: false,
        },
      };
    case types.UPDATE_EMPLOYEE.ERROR:
      return {
        ...state,
        selectedEmployee: {
          ...state.selectedEmployee,
          loading: false,
        },
      };
    case types.CLEAR_STATE.SUCCESS:
      return {
        ...state,
        selectedJob: INITIAL_STATE.selectedJob,
        selectedEmployee: INITIAL_STATE.selectedEmployee,
      };
    case types.SET_JOB_TO_EDIT.SUCCESS:
      return {
        ...state,
        jobToCreate: {
          fields: {
            ...payload,
            expenses: payload.expenses * 100,
          },
        },
      };
    case types.EDIT_JOB.START:
      return {
        ...state,
        jobToCreate: {
          ...state.jobToCreate,
          loading: true,
        },
      };
    case types.EDIT_JOB.SUCCESS:
      return {
        ...state,
        jobToCreate: {
          ...state.jobToCreate,
          loading: false,
          success: true,
        },
      };
    case types.EDIT_JOB.ERROR:
      return {
        ...state,
        jobToCreate: {
          ...state.jobToCreate,
          loading: false,
          success: false,
        },
      };
    case types.DELETE_SELECTED_CONSULTING.START:
      return {
        ...state,
        deletingConsulting: true,
      };
    case types.DELETE_SELECTED_CONSULTING.SUCCESS:
      return {
        ...state,
        deletingConsulting: false,
        selectedJob: {
          ...state.selectedJob,
          consultings: state?.selectedJob?.consultings
            ? state.selectedJob.consultings.filter(
                con => con.id !== payload.consultingId
              )
            : [],
        },
      };
    case types.DELETE_SELECTED_CONSULTING.ERROR:
      return {
        ...state,
        deletingConsulting: false,
      };
    case types.DELETE_JOB.SUCCESS:
      return {
        ...state,
        jobs: state.jobs.filter(job => job.id !== payload),
      };
    default:
      return state;
  }
};

export default reducer;
