import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ReactComponent as Logo } from 'images/logo-nma.svg';
import { useAuth } from 'utils';
import { ExcelJobs, ExcelEmployees } from './components';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Header = ({ open, setOpen }) => {
  const [accountMenuOpen, setAccountMenuOpen] = useState(null);
  const [excelMenuOpen, setExelMenuOpen] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const state = useSelector(state => state);
  const jobs = state.jobs.jobs;
  const employees = state.employees.employees;

  const handleMenu = event => {
    setAccountMenuOpen(event.currentTarget);
  };

  const handleClose = () => {
    setAccountMenuOpen(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const isHomePage = location => /^\/home/.test(location.pathname);
  return (
    <Box className="header-container">
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Logo
              onClick={() => {
                if (open) setOpen(!open);
                navigate('/home', { replace: true });
              }}
              className="cursor-pointer"
            />
          </Box>
          <div className="d-flex align-items-center">
            {isHomePage(location) && (
              <IconButton
                size="large"
                aria-label="export-data-to-excel"
                aria-controls="excel-export"
                aria-haspopup="true"
                onClick={event => setExelMenuOpen(event.currentTarget)}
                color="inherit"
              >
                <DownloadForOfflineIcon />
              </IconButton>
            )}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            {isHomePage(location) && (
              <Menu
                id="excel-export"
                anchorEl={excelMenuOpen}
                open={Boolean(excelMenuOpen)}
                onClose={() => setExelMenuOpen(null)}
                TransitionComponent={Fade}
              >
                <MenuItem>
                  <ExcelJobs jobs={jobs} />
                </MenuItem>
                <MenuItem>
                  <ExcelEmployees employees={employees} />
                </MenuItem>
              </Menu>
            )}
            <Menu
              id="menu-appbar"
              anchorEl={accountMenuOpen}
              open={Boolean(accountMenuOpen)}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              <MenuItem
                onClick={() => {
                  navigate('/admin');
                  handleClose();
                }}
              >
                Διαχείρηση
              </MenuItem>
              <MenuItem
                onClick={() =>
                  auth.signout({
                    callback: () => {
                      navigate('/');
                      handleClose();
                    },
                  })
                }
              >
                Αποσύνδεση
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
