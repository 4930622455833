import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import toNumber from 'lodash/toNumber';
import get from 'lodash/get';

import { CustomDialog, FormField } from 'components';
import { DateDialog } from './components';
import {
  updateJobField,
  clearJobFields,
  addJob,
  editJob,
  getJobs,
  setJobEmployee,
  clearState,
} from 'store/jobs/actions';
import { getEmployees } from 'store/employees/actions';
import { editEmployee } from 'store/employees/actions';
import { fields } from '../../fields';
import {
  calculateTotalCost,
  handleDisabled,
  handleChange,
  checkJobConflicts,
} from './utils';

const JobDialog = ({ open, setOpen, edit }) => {
  const [dateDialogOpen, setDateDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const job = useSelector(state => state.jobs.jobToCreate);
  const jobs = useSelector(state => state.jobs.jobs);
  const employees = useSelector(state => state.employees.employees);
  const selectedEmployee = useSelector(state => state.jobs.selectedEmployee);

  useEffect(() => {
    if (job.success && !job.loading) {
      dispatch(getJobs());
      dispatch(getEmployees());
      setOpen(false);
      setDateDialogOpen(false);
    }
  }, [job, setOpen]);

  useEffect(() => {
    // Added default value on mount
    if (open && !edit) {
      dispatch(
        updateJobField({
          key: 'startDate',
          value: moment().format(),
        })
      );
      dispatch(
        updateJobField({
          key: 'endDate',
          value: moment().format(),
        })
      );
    }
  }, [dispatch, open]);

  //unmount
  // useEffect(() => () => dispatch(clearState()), [dispatch]);

  const dialogActions = [
    {
      label: 'ΑΚΥΡΩΣΗ',
      type: 'secondary',
      disabled: job.loading,
      onClick: () => {
        setOpen(false);
        dispatch(clearJobFields());
      },
    },
    {
      label: edit ? 'ΕΠΕΞΕΡΓΑΣΙΑ' : 'ΠΡΟΣΘΗΚΗ',
      type: 'primary',
      disabled: handleDisabled({ job, fields }),
      onClick: () => {
        if (edit) {
          if (checkJobConflicts({ job, jobs })) {
            setOpen(false);
            setDateDialogOpen(true);
            return;
          }
          dispatch(
            editJob({
              ...job.fields,
              expenses: toNumber(job.fields.expenses) / 100,
              jobId: job.fields['id'],
              totalCost: calculateTotalCost({ job }),
            })
          );
        } else {
          dispatch(
            addJob({
              ...job.fields,
              id: uuidv4(),
              totalCost: calculateTotalCost({ job }),
              vat: toNumber(job.fields['vat']),
              expenses: toNumber(get(job.fields, 'expenses', 0) / 100),
              startDate: job.fields['startDate'] || moment().format(),
            })
          );
        }
      },
    },
  ];

  return (
    <CustomDialog
      open={open}
      setOpen={() => {
        setOpen(false);
        dispatch(clearState());
      }}
      title={`${edit ? 'Επεξεργασία' : 'Προσθήκη'} έργου`}
      actions={dialogActions}
    >
      <div className="job-dialog">
        {fields
          .filter(field => !field.hiddenFromDialog)
          .map((field, index) => {
            const value =
              field.component === 'datepicker'
                ? job.fields[field.name] || moment()
                : job.fields[field.name] || '';
            return (
              <Fragment key={index}>
                <FormField
                  label={field.label}
                  name={field.name}
                  component={field.component}
                  onChange={event => {
                    handleChange({
                      event,
                      field,
                      component: field.component,
                      dispatch,
                      updateJobField,
                    });
                  }}
                  value={value}
                  options={field.options}
                  required={field.required}
                />
              </Fragment>
            );
          })}
        {/* <div>Συνολικό κόστος: {calculateTotalCost({ job })} €</div> */}
      </div>
      {job.fields.employees && (
        <DateDialog
          open={dateDialogOpen}
          selectedJob={job.fields}
          loading={job.loading}
          setJobEmployee={setJobEmployee}
          updateJobField={updateJobField}
          employees={employees}
          initialJob={jobs.find(j => j.id === job.fields.id)}
          selectedEmployee={selectedEmployee}
          handleCancel={() => {
            setDateDialogOpen(false);
            setOpen(true);
            dispatch(clearState());
          }}
          editJob={editJob}
          editEmployee={editEmployee}
        />
      )}
    </CustomDialog>
  );
};

export default JobDialog;
