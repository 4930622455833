import { types } from './ui/types';

export const successNotifications = message => {
  return {
    update: {
      type: types.SHOW_NOTIFICATION.SUCCESS,
      payload: {
        open: true,
        message: message || 'Οι αλλαγές αποθηκέυτηκαν!',
        type: 'success',
      },
    },
    create: {
      type: types.SHOW_NOTIFICATION.SUCCESS,
      payload: {
        open: true,
        message: message || 'Τα στοιχεία καταχωρήθηκαν!',
        type: 'success',
      },
    },
  };
};

export const errorNotification = message => {
  return {
    type: types.SHOW_NOTIFICATION.SUCCESS,
    payload: {
      open: true,
      message: message || 'Κάτι πήγε λάθος, προσπάθησε ξανά!',
      type: 'error',
    },
  };
};
