import { types } from './types';

const INITIAL_STATE = {
  snackbar: {
    open: false,
    message: '',
    type: '',
  },
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.SHOW_NOTIFICATION.SUCCESS:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          ...payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
