import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';

import { getDocument } from 'firebase';
import { setCurrentUser } from 'store/user/actions';

const SignIn = ({ email, password, handleChange, auth }) => {
  const { loading } = auth;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const to = '/home';
  return (
    <Grid
      xs={6}
      className="sign-in-container"
      container
      justifyContent="center"
      direction="column"
      item
    >
      <h2 className="sign-in-container__title">Master Budget</h2>
      <form
        className="d-flex flex-column"
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          return auth.signin({
            payload: { email, password },
            callback: user => {
              getDocument('users', user.uid).then(response => {
                const { email, uid, accessToken } = user;
                const { name, lastname, admin, accessRights } = response;
                const payload = {
                  email,
                  uid,
                  accessToken,
                  name,
                  lastname,
                  admin,
                  accessRights,
                };
                dispatch(setCurrentUser(payload));
                navigate(to, { replace: true });
              });
            },
          });
        }}
      >
        <TextField
          label="Email"
          className="sign-in-container__field"
          name="email"
          onChange={event => handleChange(event)}
        />
        <TextField
          label="Κωδικός"
          type="password"
          name="password"
          onChange={event => handleChange(event)}
        />
        <Button
          type="submit"
          variant="contained"
          className="sign-in-container__button"
          disabled={loading}
        >
          ΕΙΣΟΔΟΣ
        </Button>
        <div className="powered-by">*Powered by TRC</div>
      </form>
    </Grid>
  );
};

export default SignIn;
