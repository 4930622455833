import React, { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Input from '@mui/material/Input';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

const StyledMenu = styled(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const Filters = ({
  filterOptions,
  onChange,
  selectedFilter,
  setSelectedFilter,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = filter => {
    setSelectedFilter(filter);
    handleClose();
  };

  return (
    <div className="filters-container">
      <div className="filters-container__group">
        <div className="filters-container__menu">
          <Button
            id="filter-button"
            aria-controls="filter-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
            className="filters-container__button"
          >
            {filterOptions.find(f => f.value === selectedFilter.value).label}
          </Button>
          <StyledMenu
            id="filter-menu"
            MenuListProps={{
              'aria-labelledby': 'filter-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {filterOptions.map((filter, index) => (
              <MenuItem
                key={index}
                onClick={() => handleItemClick(filter)}
                disableRipple
              >
                {filter.label}
              </MenuItem>
            ))}
          </StyledMenu>
        </div>
        {selectedFilter.type === 'text' ? (
          <Input
            placeholder="Αναζήτηση"
            className="filters-container__input"
            disableUnderline
            onChange={onChange}
          />
        ) : (
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              {selectedFilter.options.map((opt, index) => (
                <FormControlLabel
                  key={index}
                  value={opt.value}
                  control={<Radio />}
                  label={opt.label}
                  onChange={onChange}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
      </div>
    </div>
  );
};

export default Filters;
