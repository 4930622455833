import React from 'react';
import TextField from '@mui/material/TextField';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Checkbox from '@mui/material/Checkbox';

import { Select } from 'components';

const FormField = ({
  component,
  label,
  value,
  options,
  required,
  onChange,
  disabled,
  hasError,
  maxLength,
  helperText,
}) => {
  const renderComponent = ({ component }) => {
    switch (component) {
      case 'datepicker':
        return (
          <DesktopDatePicker
            label={label}
            inputFormat="DD/MM/YYYY"
            value={value}
            onChange={onChange}
            renderInput={params => (
              <TextField {...params} fullWidth required={required} />
            )}
          />
        );
      case 'text':
        return (
          <TextField
            label={label}
            value={value}
            onChange={onChange}
            variant="outlined"
            required={required}
            disabled={disabled}
            fullWidth
            error={hasError}
            inputProps={{ maxLength }}
            helperText={helperText}
          />
        );
      case 'checkbox':
        return (
          <div className="d-flex align-items-center">
            <Checkbox
              checked={!!value}
              onChange={event => onChange(event)}
              inputProps={{ 'aria-label': 'controlled' }}
              sx={{ paddingLeft: 0 }}
              disabled={disabled}
            />
            <div>{label}</div>
          </div>
        );
      case 'select':
        return (
          <Select
            value={value}
            label={label}
            onChange={event => onChange(event)}
            options={options}
            required={required}
          />
        );
      default:
        return <div>default</div>;
    }
  };
  return renderComponent({ component });
};

export default FormField;
