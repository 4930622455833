import * as React from 'react';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import { Button, Select } from 'components';
import { SettingsPower } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomizedDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    padding: theme.spacing(1.5),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const CustomizedDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: '10px 24px !important',
}));

const prepareConsultings = consultings =>
  consultings
    .filter(c => c?.job === undefined || c.job === null)
    .map(c => ({ value: c.id, label: c.description }));

const ConsultingJobLinkDialog = ({
  open,
  closeHandler,
  handleSubmit,
  selectedJob,
  consultings,
}) => {
  const [selectedConsulting, setSelectedConsulting] = useState(null);

  useEffect(() => {
    setSelectedConsulting(null);
  }, [open]);

  const handleSelectionChange = event => {
    setSelectedConsulting(event.target.value);
  };

  const preparedConsultings = prepareConsultings(consultings);

  return (
    <CustomizedDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted={false}
      onClose={closeHandler}
      aria-describedby="consulting-job-link-dialog"
    >
      <DialogTitle>{'Σύνδεση Ανάθεσης με Έργο'}</DialogTitle>
      <CustomizedDialogContent>
        {preparedConsultings.length ? (
          <Select
            value={selectedConsulting ? selectedConsulting : ''}
            label="Ανάθεση"
            options={preparedConsultings}
            onChange={handleSelectionChange}
            required={true}
          />
        ) : (
          <DialogContentText>
            'Δεν υπάρχουν διαθέσιμες αναθέσεις'
          </DialogContentText>
        )}
      </CustomizedDialogContent>
      <DialogActions>
        <Button
          onClick={closeHandler}
          variant={'primary'}
          disabled={false}
          label={preparedConsultings.length ? 'Ακύρωση' : 'Κλείσιμο'}
          color={'primary'}
        />
        {preparedConsultings.length && (
          <Button
            onClick={() => {
              handleSubmit(selectedConsulting, selectedJob.id);
              closeHandler();
            }}
            variant={'contained'}
            disabled={selectedConsulting ? false : true}
            label={'Προσθήκη'}
            color={'primary'}
          />
        )}
      </DialogActions>
    </CustomizedDialog>
  );
};

export default ConsultingJobLinkDialog;
