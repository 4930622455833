import omit from 'lodash/omit';

import { successNotifications, errorNotification } from 'store/utils';
import { types } from './types';
import {
  addDocumentWithId,
  getAllDocuments,
  updateDocument,
  getDocument,
  deleteDocument,
} from 'firebase';

export const updateEmployeeField = payload => dispatch => {
  dispatch({ type: types.UPDATE_EMPLOYEE_FIELD.SUCCESS, payload });
};

export const clearEmployeeFields = () => dispatch => {
  dispatch({ type: types.CLEAR_FIELDS.SUCCESS, payload: {} });
};

export const addEmployee = payload => dispatch => {
  dispatch({ type: types.ADD_EMPLOYEE.START, payload: { loading: true } });
  addDocumentWithId('employees', payload.id, payload)
    .then(() => {
      return [
        dispatch({
          type: types.ADD_EMPLOYEE.SUCCESS,
          payload: { loading: false, success: true },
        }),
        dispatch(successNotifications().create),
      ];
    })
    .catch(error => {
      return [
        dispatch({ type: types.ADD_EMPLOYEE.ERROR }),
        dispatch(errorNotification(error.message)),
      ];
    });
};

export const getEmployees = () => dispatch => {
  dispatch({ type: types.GET_EMPLOYEES.START });
  getAllDocuments('employees')
    .then(response => {
      return dispatch({
        type: types.GET_EMPLOYEES.SUCCESS,
        payload: response,
      });
    })
    .catch(error => {
      return [
        dispatch({ type: types.GET_EMPLOYEES.ERROR }),
        dispatch(errorNotification(error.message)),
      ];
    });
};

export const setEmployeeToEdit = payload => dispatch => {
  dispatch({ type: types.SET_EMPLOYEE_TO_EDIT.SUCCESS, payload });
};

export const editEmployee = payload => dispatch => {
  const { employeeId } = payload;
  dispatch({ type: types.EDIT_EMPLOYEE.START });
  updateDocument('employees', employeeId, omit(payload, ['employeeId']))
    .then(response => {
      return [
        dispatch({ type: types.EDIT_EMPLOYEE.SUCCESS }),
        dispatch(successNotifications().update),
      ];
    })
    .catch(error => [
      dispatch({ type: types.EDIT_EMPLOYEE.ERROR }),
      dispatch(errorNotification(error.message)),
    ]);
};

export const setSelectedEmployee = payload => dispatch => {
  dispatch({ type: types.SET_SELECTED_EMPLOYEE.SUCCESS, payload });
};

export const getEmployee = collectionId => dispatch => {
  dispatch({ type: types.GET_EMPLOYEE.START });
  getDocument('employees', collectionId)
    .then(response => {
      dispatch({ type: types.GET_EMPLOYEE.SUCCESS, payload: response });
    })
    .catch(error => {
      return [
        dispatch({ type: types.GET_EMPLOYEE.ERROR, payload: error }),
        dispatch(errorNotification(error.message)),
      ];
    });
};

export const deleteEmployee = payload => dispatch => {
  const { id, callback } = payload;
  dispatch({ type: types.DELETE_EMPLOYEE.START });
  deleteDocument('employees', id)
    .then(() => {
      dispatch({ type: types.DELETE_EMPLOYEE.SUCCESS, payload: id });
      dispatch(successNotifications().update);
      callback(false);
    })
    .catch(error => {
      dispatch({ type: types.DELETE_EMPLOYEE.ERROR });
      dispatch(errorNotification(error.message));
    });
};
