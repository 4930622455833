import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { routes } from 'routes/config';
import { useNavigate, useLocation } from 'react-router-dom';

const Sidebar = ({ open, setOpen, DrawerHeader, Drawer }) => {
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box id="sidebar" className="sidebar-container">
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {routes
            .filter(route => route.usedOnSidebar)
            .map(({ Icon, label, path }, index) => (
              <ListItem
                key={index}
                button
                onClick={() => {
                  if (open) setOpen(!open);
                  navigate(path, { replace: true });
                }}
              >
                <ListItemIcon
                  sx={{
                    marginRight: '8px',
                    color: path === location.pathname ? '#3b59c4' : '',
                  }}
                >
                  {Icon}
                </ListItemIcon>
                <ListItemText
                  primary={label}
                  primaryTypographyProps={{
                    color: path === location.pathname ? '#3b59c4' : '',
                  }}
                />
              </ListItem>
            ))}
        </List>
      </Drawer>
      <Box id="outlet-components-wrapper" className="outlet-container">
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
};

export default Sidebar;
