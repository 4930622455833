import React from 'react';
import isEmpty from 'lodash/isEmpty';
import toNumber from 'lodash/toNumber';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from 'components';
import { options } from 'views/job/utils';

const ChooseEmployee = ({ setJobEmployee, employees, selectedJob }) => {
  const dispatch = useDispatch();
  const selectedEmployee = useSelector(state => state.jobs.selectedEmployee);
  return (
    <div className="selected-job__employees-dropdown">
      <Select
        value={selectedEmployee.id}
        label="Εργαζόμενοι"
        onChange={event => {
          const employee = employees.find(em => em.id === event.target.value);
          dispatch(
            setJobEmployee({
              ...employee,
              id: event.target.value,
              firstName: employee.firstName,
              lastName: employee.lastName,
              rate: toNumber(employee.rate),
              activeJobs: employee.activeJobs || [],
              isExternal: employee.isExternal || false,
              occupation: isEmpty(employee.activeJobs)
                ? {}
                : employee.activeJobs.find(j => j.id === selectedJob.id)
                ? employee.activeJobs.find(j => j.id === selectedJob.id)
                    .occupation
                : {},
            })
          );
        }}
        options={options({ employees })}
      />
    </div>
  );
};

export default ChooseEmployee;
