import { types } from './types';

const INITIAL_STATE = {
  employees: [],
  employeeToCreate: {
    fields: {},
  },
  selectedEmployee: {},
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.UPDATE_EMPLOYEE_FIELD.SUCCESS:
      return {
        ...state,
        employeeToCreate: {
          ...state.employeeToCreate,
          fields: {
            ...state.employeeToCreate.fields,
            [payload.key]: payload.value,
          },
        },
      };
    case types.CLEAR_FIELDS.SUCCESS:
      return {
        ...state,
        employeeToCreate: {
          fields: {},
        },
      };
    case types.ADD_EMPLOYEE.START:
      return {
        ...state,
        employeeToCreate: {
          ...state.employeeToCreate,
          ...payload,
        },
      };
    case types.ADD_EMPLOYEE.SUCCESS:
      return {
        ...state,
        employeeToCreate: {
          ...state.employeeToCreate,
          ...payload,
        },
      };
    case types.ADD_EMPLOYEE.ERROR:
      return {
        ...state,
        employeeToCreate: {
          ...state.employeeToCreate,
          loading: false,
          success: false,
        },
      };
    case types.GET_EMPLOYEES.START:
      return {
        ...state,
        loading: true,
      };
    case types.GET_EMPLOYEES.SUCCESS:
      return {
        ...state,
        employees: payload,
        loading: false,
      };
    case types.GET_EMPLOYEES.ERROR:
      return {
        ...state,
        loading: false,
      };
    case types.SET_EMPLOYEE_TO_EDIT.SUCCESS:
      return {
        ...state,
        employeeToCreate: {
          fields: {
            ...payload,
          },
        },
      };
    case types.EDIT_EMPLOYEE.START:
      return {
        ...state,
        employeeToCreate: {
          ...state.employeeToCreate,
          loading: true,
        },
      };
    case types.EDIT_EMPLOYEE.SUCCESS:
      return {
        ...state,
        employeeToCreate: {
          ...state.employeeToCreate,
          loading: false,
          success: true,
        },
      };
    case types.EDIT_EMPLOYEE.ERROR:
      return {
        ...state,
        employeeToCreate: {
          ...state.employeeToCreate,
          loading: false,
          success: false,
        },
      };
    case types.SET_SELECTED_EMPLOYEE.SUCCESS:
      return {
        ...state,
        selectedEmployee: payload,
      };
    case types.GET_EMPLOYEE.SUCCESS:
      return {
        ...state,
        selectedEmployee: payload,
      };
    case types.DELETE_EMPLOYEE.SUCCESS:
      return {
        ...state,
        employees: state.employees.filter(em => em.id !== payload),
      };
    default:
      return state;
  }
};

export default reducer;
