import React from 'react';

import { Document, Text, Page, Font } from '@react-pdf/renderer';

import {
  Table,
  TableHeader,
  TableBody,
  TableCell,
  DataTableCell,
} from '@david.kucsai/react-pdf-table';

import Comfortaa from 'fonts/Comfortaa/ComfortaaRegular.ttf';

import { styles, Report } from './config';

Font.register({
  family: 'Comfortaa',
  src: Comfortaa,
});

const EmployeePdfReport = ({
  employee,
  appName = 'employee',
  companyName = 'ΝΕΑ ΜΗΤΡΟΠΟΛΙΤΙΚΗ ΑΤΤΙΚΗ',
}) => {
  return (
    <Document style={styles.document} title={`${appName} | Εργαζόμενος`}>
      <Page size="A4" style={styles.page} orientation="portrait">
        <Text style={styles.title}>
          {`${appName} | ${companyName} | Εργαζόμενος`}
        </Text>
        <Text style={styles.subtitle}>{`Στοιχεία εργαζομένου:`}</Text>
        <Table data={[employee]}>
          <TableHeader>
            {Report.employee.fields.map((f, idx) => (
              <TableCell key={idx} style={styles.tablecell}>
                {f}
              </TableCell>
            ))}
          </TableHeader>
          <TableBody>
            {Report.employee.callbacks.map((c, idx) => (
              <DataTableCell
                key={idx}
                style={styles.datatablecell}
                getContent={c}
              />
            ))}
          </TableBody>
        </Table>
        <Text style={styles.subtitle}>{`Έργα:`}</Text>
        <Table data={employee.preparedJobs}>
          <TableHeader>
            {Report.jobs.fields.map((f, idx) => (
              <TableCell key={idx} style={styles.tablecell}>
                {f}
              </TableCell>
            ))}
          </TableHeader>
          <TableBody>
            {Report.jobs.callbacks.map((c, idx) => (
              <DataTableCell
                key={idx}
                style={styles.datatablecell}
                getContent={c}
              />
            ))}
          </TableBody>
        </Table>
      </Page>
    </Document>
  );
};

export default EmployeePdfReport;
