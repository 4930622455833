import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomDialog, FormField } from 'components';
import { validations } from 'utils';
import {
  updateEmployeeField,
  clearEmployeeFields,
  addEmployee,
  getEmployees,
  editEmployee,
} from 'store/employees/actions';
import { showNotification } from 'store/ui/actions';
import { fields } from '../../fields';

const EmployeeDialog = ({ open, setOpen, setEditEmployee, edit }) => {
  const [hasError, setHasError] = useState({});
  const dispatch = useDispatch();
  const employee = useSelector(state => state.employees.employeeToCreate);
  const employees = useSelector(state => state.employees.employees);

  useEffect(() => {
    if (employee.success && !employee.loading) {
      dispatch(getEmployees());
      setOpen(false);
      setEditEmployee(false);
    }
  }, [employee, setOpen]);

  const handleDisabled = () => {
    const requiredFields = fields.filter(f => f.required);
    let disabled = requiredFields.some(
      f => !employee.fields[f.name] || hasError[f.name]
    );
    return disabled || employee.loading;
  };

  const handleFieldDisabled = field => {
    return (field.name === 'taxNumber' || field.name === 'overbooking') && edit;
  };

  const dialogActions = [
    {
      label: 'ΑΚΥΡΩΣΗ',
      type: 'secondary',
      disabled: employee.loading,
      onClick: () => {
        setOpen(false);
        setEditEmployee(false);
        dispatch(clearEmployeeFields());
      },
    },
    {
      label: edit ? 'ΕΠΕΞΕΡΓΑΣΙΑ' : 'ΠΡΟΣΘΗΚΗ',
      type: 'primary',
      disabled: handleDisabled(),
      onClick: () => {
        if (edit) {
          dispatch(
            editEmployee({
              ...employee.fields,
              employeeId: employee.fields.id,
              fullName: `${employee.fields.lastName} ${employee.fields.firstName}`,
            })
          );
        } else {
          const id = `${employee.fields.taxNumber}${
            employee.fields.overbooking ? '_1' : ''
          }`;
          const isAlreadyRegistered = employees.some(em => em.id === id);
          if (isAlreadyRegistered) {
            return dispatch(
              showNotification({
                open: true,
                message: 'Ο εργαζόμενος είναι ήδη εγγεγραμμένος!',
                type: 'error',
              })
            );
          }
          dispatch(
            addEmployee({
              ...employee.fields,
              isExternal: employee.fields.isExternal || false,
              fullName: `${employee.fields.lastName} ${employee.fields.firstName}`,
              id,
            })
          );
        }
      },
    },
  ];

  const validateField = (value, field) => {
    switch (field) {
      case 'taxNumber':
        return setHasError({
          [field]: !validations.validateOnlyNumbers(value),
        });
      default:
        return '';
    }
  };

  const handleChange = (event, field, component) => {
    let value = '';
    switch (component) {
      case 'checkbox':
        value = event.target.checked;
        break;
      default:
        value =
          field.type === 'number'
            ? event.target.value.replace(',', '.')
            : event.target.value;
        break;
    }
    validateField(value, field.name);
    dispatch(
      updateEmployeeField({
        key: field.name,
        value,
      })
    );
  };

  const renderInnerFields = (field, value) => {
    if (field.component === 'select' && value === 4) {
      return field.innerFields.map((innerField, index) => {
        const fieldValue = employee.fields[innerField.name] || '';
        return (
          <FormField
            key={index}
            {...innerField}
            value={fieldValue}
            onChange={event => {
              handleChange(event, innerField, innerField.component);
            }}
          />
        );
      });
    }
  };

  return (
    <CustomDialog
      open={open}
      setOpen={data => {
        setOpen(data);
        setEditEmployee(data);
      }}
      title={`${edit ? 'Επεξεργασία' : 'Προσθήκη'} εργαζόμενου`}
      actions={dialogActions}
    >
      <div className="employee-dialog">
        {fields
          .filter(field => !field.hiddenFromDialog)
          .map((field, index) => {
            const value = employee.fields[field.name] || '';
            return (
              <Fragment key={index}>
                <FormField
                  label={field.label}
                  name={field.name}
                  component={field.component}
                  onChange={event => {
                    handleChange(event, field, field.component);
                  }}
                  value={value}
                  required={field.required}
                  options={field.options}
                  hasError={hasError[field.name]}
                  maxLength={field.maxLength}
                  helperText={hasError[field.name] && field.errorMessage}
                  disabled={handleFieldDisabled(field)}
                />
                {field.innerFields && renderInnerFields(field, value)}
              </Fragment>
            );
          })}
      </div>
    </CustomDialog>
  );
};

export default EmployeeDialog;
