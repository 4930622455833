import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  document: {
    fontFamily: 'Comfortaa',
  },
  page: {
    padding: '32px',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  title: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: '12px',
  },
  subtitle: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: '8px',
  },
  tablecell: {
    fontSize: 8,
    padding: '4px',
    textAlign: 'center',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
  },
  datatablecell: {
    fontSize: 8,
    padding: '4px',
    textAlign: 'center',
    borderLeft: 'none',
    borderRight: 'none',
  },
  sectiontitle: {
    fontSize: 12,
    textAlign: 'left',
    marginBottom: '12px',
    marginTop: '24px',
  },
});

export const EmployeesTable = {
  fields: {
    fullNameHeader: 'Ονοματεπώνυμο',
    occupationHeader: 'Ανθρωποχρόνος',
    totalCostHeader: 'Συνολικό κόστος',
    contractHeader: 'Σύμβαση',
  },
};
