import omit from 'lodash/omit';

import { successNotifications, errorNotification } from 'store/utils';
import {
  getAllDocuments,
  addDocumentWithId,
  updateDocument,
  deleteDocument,
} from 'firebase';
import { types } from './types';
import { getJobs } from 'store/jobs/actions';

export const getConsultings = () => dispatch => {
  dispatch({ type: types.GET_CONSULTINGS.START });
  getAllDocuments('consultings')
    .then(response => {
      return dispatch({
        type: types.GET_CONSULTINGS.SUCCESS,
        payload: response,
      });
    })
    .catch(error => {
      return [
        dispatch({ type: types.GET_CONSULTINGS.ERROR }),
        dispatch(errorNotification(error.message)),
      ];
    });
};

export const updateConsultingField = payload => dispatch => {
  dispatch({ type: types.UPDATE_CONSULTING_FIELD.SUCCESS, payload });
};

export const addConsulting = payload => dispatch => {
  dispatch({ type: types.ADD_CONSULTING.START, payload: { loading: true } });
  addDocumentWithId('consultings', payload.id, payload)
    .then(() => {
      dispatch(getJobs());
      return dispatch({
        type: types.ADD_CONSULTING.SUCCESS,
        payload: { loading: false, success: true },
      });
    })
    .catch(error => {
      console.log(error, 'ERROR');
      return dispatch({ type: types.ADD_CONSULTING.ERROR });
    });
};

export const editConsulting = payload => dispatch => {
  const { consultingId } = payload;
  dispatch({ type: types.EDIT_CONSULTING.START });
  updateDocument('consultings', consultingId, omit(payload, ['consultingId']))
    .then(response => {
      return [
        dispatch({ type: types.EDIT_CONSULTING.SUCCESS }),
        dispatch(successNotifications().update),
      ];
    })
    .catch(error => [
      dispatch({ type: types.EDIT_CONSULTING.ERROR }),
      dispatch(errorNotification(error.message)),
    ]);
};

export const deleteConsulting = payload => dispatch => {
  const { id } = payload;
  dispatch({ type: types.DELETE_CONSULTING.START });
  deleteDocument('consultings', id)
    .then(() => {
      dispatch({ type: types.DELETE_CONSULTING.SUCCESS, payload: id });
    })
    .catch(error => {
      dispatch({ type: types.DELETE_CONSULTING.ERROR });
    });
};

export const clearConsultingFields = () => dispatch => {
  dispatch({ type: types.CLEAR_CONSULTING_FIELDS.SUCCESS });
};

export const setConsultingToEdit = payload => dispatch => {
  dispatch({ type: types.SET_CONSULTING_TO_EDIT.SUCCESS, payload });
};
