import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import toNumber from 'lodash/toNumber';
import toLower from 'lodash/toLower';
import includes from 'lodash/includes';
import moment from 'moment';

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import {
  Filters,
  Fab,
  Loading,
  CustomTable,
  FSDialog,
  ReportPDFViewer,
  ReportPDFDocument,
} from 'components';
import { JobDialog } from './components';
import {
  getJobs,
  setSelectedJob,
  setJobToEdit,
  clearJobFields,
  deleteJob,
} from 'store/jobs/actions';

import { prepareFilteredJobsForPDF } from './utils';

import { ReportType } from 'components/ReportPDFDocument';

import { getEmployees, editEmployee } from 'store/employees/actions';
import { getConsultings, deleteConsulting } from 'store/consultings/actions';
import { fields as columns } from './fields';
import { filterOptions } from './content';
import { formattedNumber, localeSort } from 'utils';
import { calculateJobTotalCosts } from 'views/job/utils';

const Jobs = () => {
  const [selectedFilter, setSelectedFilter] = useState(filterOptions[0]);
  const [open, setOpen] = useState(false);
  const [openFullScreenDialog, setOpenFullScreenDialog] = useState(false);
  const [editJob, setEditJob] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const jobs = useSelector(state => state.jobs.jobs);
  const loading = useSelector(state => state.jobs.loading);
  const employees = useSelector(state => state.employees.employees);
  const [filteredJobs, setJobs] = useState([]);

  useEffect(() => {
    dispatch(getJobs());
    dispatch(getEmployees());
    dispatch(getConsultings());
  }, [dispatch]);

  useEffect(() => {
    if (jobs.length) {
      const cloneJobs = [...jobs];
      const sortedJobs = localeSort(cloneJobs, 'name');
      setJobs(sortedJobs);
    }
  }, [jobs]);

  const renderColumnValue = (value, column, row) => {
    const { component } = column;
    switch (component) {
      case 'datepicker':
        return moment(value).format('DD/MM/YYYY');
      case 'checkbox':
        return value ? 'Ναι' : 'Όχι';
      case 'text':
        return column.name === 'totalCost'
          ? calculateJobTotalCosts(row).netCost || '-'
          : column.name === 'internalEmployeesCost'
          ? calculateJobTotalCosts(row).internalEmployeesCost
          : column.type === 'number'
          ? value
            ? formattedNumber(value)
            : '-'
          : column.type === 'percentage'
          ? value
            ? `${Math.round(value * 100)}%`
            : '-'
          : value;
      case 'select':
        if (value) {
          const option = column.options.find(
            opt => opt.value === value || toNumber(opt.value) === value
          );
          return option ? option.label : '-';
        } else {
          return '-';
        }
      default:
        return value;
    }
  };

  const renderFooterValue = column => {
    switch (column.name) {
      case 'netCost':
        let totalNet = 0;
        filteredJobs.map(
          job => (totalNet = totalNet + toNumber(job.netCost || 0))
        );
        return formattedNumber(totalNet);
      case 'totalCost':
        let totalCost = 0;
        filteredJobs.map(
          job =>
            (totalCost =
              totalCost + calculateJobTotalCosts(job).totalCostUnformatted)
        );
        return formattedNumber(totalCost);
      case 'name':
        return 'Σύνολο';
      default:
        return '-';
    }
  };

  const handleDelete = (row, callback) => {
    const consultingsToDelete =
      row.consultings && !isEmpty(row.consultings) ? row.consultings : null;
    if (consultingsToDelete) {
      consultingsToDelete.map(con =>
        dispatch(deleteConsulting({ id: con.id }))
      );
    }
    const updatedEmployees = employees.map(em =>
      em.activeJobs && !isEmpty(em.activeJobs)
        ? {
            ...em,
            activeJobs: em.activeJobs.filter(j => j.id !== row.id),
          }
        : em
    );
    updatedEmployees.map(em =>
      dispatch(editEmployee({ ...em, employeeId: em.id }))
    );
    dispatch(
      deleteJob({
        id: row.id,
        callback: () => {
          callback(false);
          dispatch(getEmployees());
        },
      })
    );
  };

  const onChange = event => {
    const filter = filterOptions.find(
      opt => opt.value === selectedFilter.value
    );
    const newJobs = jobs.filter(job =>
      includes(toLower(job[filter.name]), event.target.value)
    );
    const sortedJobs = localeSort(newJobs, 'name');
    setJobs(sortedJobs);
  };

  return (
    <Fragment>
      {loading ? (
        <Loading />
      ) : isEmpty(jobs) ? (
        <>
          <div className="d-flex align-items-center justify-content-between mt-2">
            <Fab
              onClick={() =>
                navigate('/', {
                  replace: true,
                })
              }
              title="Πίσω"
              icon={<ArrowBackRoundedIcon />}
            />
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center height-90vh">
            <div className="mb-2">Προσθέστε ένα έργο</div>
            <Fab onClick={() => setOpen(true)} />
          </div>
        </>
      ) : (
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <Fab
              onClick={() =>
                navigate('/', {
                  replace: true,
                })
              }
              title="Πίσω"
              icon={<ArrowBackRoundedIcon />}
            />
            <Filters
              filterOptions={filterOptions}
              onChange={onChange}
              selectedFilter={selectedFilter}
              setSelectedFilter={filter => {
                setJobs(jobs);
                setSelectedFilter(filter);
              }}
            />
            <div className="d-flex align-items-center">
              <Fab
                onClick={() => {
                  setOpen(true);
                  setEditJob(false);
                  dispatch(clearJobFields());
                }}
              />
              <Fab
                onClick={() => setOpenFullScreenDialog(true)}
                title="Εξαγωγή ως PDF"
                icon={<PictureAsPdfIcon fontSize="medium" />}
              />
            </div>
          </div>
          <CustomTable
            columns={columns.filter(column => !column.hiddenFromTable)}
            rows={filteredJobs}
            renderColumnValue={renderColumnValue}
            handleEdit={row => {
              setOpen(true);
              setEditJob(true);
              dispatch(setJobToEdit(row));
            }}
            withEdit={() => true}
            handleDelete={(row, callback) => handleDelete(row, callback)}
            handleNavigate={row => {
              dispatch(setSelectedJob(row));
              navigate(`/job/${row.id}`, {
                replace: true,
              });
            }}
            withFooter
            renderFooterValue={renderFooterValue}
          />
        </div>
      )}
      <JobDialog open={open} setOpen={setOpen} edit={editJob} />
      <FSDialog
        title="Έργα - Εξαγωγή σε PDF"
        open={openFullScreenDialog}
        closeHandler={() => setOpenFullScreenDialog(false)}
      >
        <ReportPDFViewer>
          <ReportPDFDocument
            type={ReportType.jobs.type}
            data={prepareFilteredJobsForPDF(filteredJobs)}
          />
        </ReportPDFViewer>
      </FSDialog>
    </Fragment>
  );
};

export default Jobs;
