import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import { useSelector, useDispatch } from 'react-redux';

import { showNotification } from 'store/ui/actions';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

const CustomSnackbar = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.ui.snackbar);
  const handleClose = () => {
    return dispatch(showNotification({ open: false }));
  };
  return (
    <Snackbar
      open={state.open}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      TransitionComponent={SlideTransition}
    >
      <Alert onClose={handleClose} severity={state.type} sx={{ width: '100%' }}>
        {state.message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
