import { db } from './';
import {
  doc,
  getDoc,
  query,
  collection,
  where,
  getDocs,
} from 'firebase/firestore';

const getDocument = async (dbCollection, collectionId) => {
  const docRef = doc(db, dbCollection, collectionId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    console.log('No such document!');
  }
};

const getMultipleDocuments = async () => {
  const q = query(collection(db, 'cities'), where('capital', '==', true));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach(doc => {
    // doc.data() is never undefined for query doc snapshots
    console.log(doc.id, ' => ', doc.data());
  });
};

const getAllDocuments = async dbCollection => {
  const querySnapshot = await getDocs(collection(db, dbCollection));
  let response = [];
  querySnapshot.forEach(doc => {
    return response.push(doc.data());
  });
  return response;
};

export { getDocument, getAllDocuments, getMultipleDocuments };
