import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { Filters, Loading, CustomTable } from 'components';
import { getUsers } from 'store/admin/actions';
import { fields as columns } from './fields';
import { filterOptions } from './content';

const Admin = () => {
  const dispatch = useDispatch();
  const users = useSelector(state => state.admin.users);
  const loading = useSelector(state => state.admin.loading);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const renderColumnValue = (value, column, row) => {
    switch (column.name) {
      case 'admin':
        return row.admin ? 'Ναι' : 'Όχι';
      default:
        return value;
    }
  };

  return (
    <Fragment>
      {loading ? (
        <Loading />
      ) : isEmpty(users) ? (
        <div className="d-flex flex-column align-items-center justify-content-center h-100">
          <div className="mb-2">Δεν υπάρχουν χρήστες</div>
        </div>
      ) : (
        <div>
          {/* <div className="d-flex align-items-center justify-content-between">
            <Filters filterOptions={filterOptions} />
          </div> */}
          <CustomTable
            rows={users}
            columns={columns}
            renderColumnValue={(value, column, row) =>
              renderColumnValue(value, column, row)
            }
          />
        </div>
      )}
    </Fragment>
  );
};

export default Admin;
