import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import {
  getConsultings,
  updateConsultingField,
  addConsulting,
  clearConsultingFields,
  editConsulting,
} from 'store/consultings/actions';
import { CustomDialog, FormField } from 'components';
import { validations } from 'utils';
import { fields } from '../../fields';

const ConsultingDialog = ({
  open,
  setOpen,
  edit,
  linkWithJob,
  updateJob,
  selectedJob,
}) => {
  const [hasError, setHasError] = useState({});
  const dispatch = useDispatch();
  const consulting = useSelector(state => state.consultings.consultingToCreate);
  const jobs = useSelector(state => state.jobs.jobs);

  useEffect(() => {
    if (consulting.success && !consulting.loading) {
      dispatch(getConsultings());
      setOpen(false);
    }
  }, [consulting, setOpen, dispatch]);

  const validateField = (value, field) => {
    switch (field) {
      case 'taxNumber':
        return setHasError({
          [field]: !validations.validateOnlyNumbers(value),
        });
      default:
        return '';
    }
  };

  const handleSubmitDisabled = () => {
    const requiredFields = fields.filter(f => f.required);
    let disabled = requiredFields
      .filter(field => (linkWithJob ? field.mainField : field))
      .some(f => !consulting.fields[f.name] || hasError[f.name]);
    return disabled || consulting.loading;
  };

  const dialogActions = [
    {
      label: 'ΑΚΥΡΩΣΗ',
      type: 'secondary',
      disabled: false,
      onClick: () => {
        setOpen(false);
        dispatch(clearConsultingFields());
      },
    },
    {
      label: edit ? 'ΕΠΕΞΕΡΓΑΣΙΑ' : 'ΠΡΟΣΘΗΚΗ',
      type: 'primary',
      disabled: handleSubmitDisabled(),
      onClick: () => {
        if (edit) {
          dispatch(
            editConsulting({
              ...consulting.fields,
              consultingId: consulting.fields['id'],
            })
          );
        } else {
          const id = uuidv4();
          dispatch(
            addConsulting({
              ...consulting.fields,
              id,
            })
          );
        }
      },
    },
  ];

  const handleChange = (event, field, component) => {
    let value = event.target.value;
    validateField(value, field.name);
    dispatch(
      updateConsultingField({
        key: field.name,
        value: field.type === 'number' ? value.replace(',', '.') : value,
      })
    );
  };

  const handleFieldDisabled = field => {
    return (
      (field.name === 'description' ||
        field.name === 'amount' ||
        field.name === 'job') &&
      edit
    );
  };

  const renderValue = (field, value) => {
    if (field.name === 'job') {
      const job = jobs.find(j => j.id === value.id);
      return job ? job.name : '';
    } else {
      return value;
    }
  };

  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      title={`${edit ? 'Επεξεργασία' : 'Προσθήκη(Συνδεση)'} ανάθεσης`}
      actions={dialogActions}
    >
      <div className="job-dialog">
        {fields
          .filter(field => !field.hiddenFromTable)
          .filter(field => (linkWithJob ? field.mainField : field))
          .map((field, index) => {
            const value = consulting.fields[field.name] || '';
            return (
              <Fragment key={index}>
                <FormField
                  label={field.label}
                  name={field.name}
                  component={field.component}
                  onChange={event => {
                    handleChange(event, field, field.component);
                  }}
                  value={renderValue(field, value)}
                  required={field.required}
                  disabled={handleFieldDisabled(field)}
                  hasError={hasError[field.name]}
                  maxLength={field.maxLength}
                  helperText={hasError[field.name] && field.errorMessage}
                  options={field.options}
                />
              </Fragment>
            );
          })}
      </div>
    </CustomDialog>
  );
};

export default ConsultingDialog;
